.page-action {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4em;
  padding: 1.5em 0;
  z-index: 11;
  background: $primaryLightColor;
  color: $textColor;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  .-toggle {
    position: relative;
    top: -0.5em;
    left: -0.5em;
  }
  &:hover,
  &:focus {
    transition: all 400ms ease;
    background: $primaryColor;
    color: $baseLightColor;
    text-decoration: none;
  }
}

.-with-page-action {
  padding-bottom: 6rem;
}
