.page.-account {
  padding-top: 4rem;
  @include respond-to('mobile') {
    padding-top: 2rem;
  }
  input[type='checkbox'] {
    width: auto;
    appearance: checkbox;
  }
}
