.card {
  @include columns(8);
  background: $baseLightColor;
  box-shadow: $shadowShallow;
  border-radius: $radiusOuter;
  padding: 2rem;
  margin-bottom: 2rem;
  position: relative;
  &__heading {
    width: 90%;
    margin: auto;
    text-align: center;
    border-bottom: 2px solid $baseDarkColor;
    padding: 0 0 1.2em 0;
    margin-bottom: 2em;
  }
  &.-feed {
    background: transparent;
    box-shadow: none;
    padding: 0;
    @include respond-to('mobile') {
      // margin:0;
      // width:100%;
    }
  }
  &.-focus {
    @include columns(6, 3);
  }
  &.-solo {
    @include columns(8, 2);
  }
  &.-wide {
    @include columns(10, 1);
  }
  &.-full {
    @include columns(12);
  }
  &.-compact {
    padding: 0.5rem;
  }
  &.-welcome {
    text-align: center;
  }
  &.-deemphasise {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  .sticky_top {
    position: sticky;
    top: 32px;
    flex: 1;
    z-index: 3;
    box-shadow: $shadowShallow;
  }
  .sticky_bottom {
    position: sticky;
    bottom: 0;
    flex: 1;
    z-index: 2000;
  }
  &.-sections {
    .heading {
      width: 90%;
      margin: auto;
      text-align: center;
      border-bottom: 2px solid $baseDarkColor;
      padding: 2em 0 1.2em 0;
      background: white;
      font-size: 1.375rem;
    }

    .tabs {
      left: 0;
      top: 0;
      width: 100%;
    }

    .footer-action {
      margin: 0 0 0 0;
      width: 100%;
      box-shadow: $shadowDeep;
    }
  }
  &.-panels,
  &.-help {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    float: none; // ??

    .heading {
      flex: 0 0 100%;
      position: static;
      left: 0;
      top: 0;
      box-sizing: border-box;
      width: 100%;

      @include respond-to('mobile') {
        padding: 0 0 3em 0;
      }
    }

    .card__content {
      display: flex;
      width: 100%;
      overflow: hidden;
    }

    .card__body {
      flex: 1 1 100%;
      padding: 2em;

      &.calendar {
        padding: 0;
        overflow: auto;
        margin-top: 0;
      }

      .heading {
        width: 90%;
        margin: auto;
        text-align: center;
        border-bottom: 2px solid $baseDarkColor;
        padding: 0 0 1.2em 0;
        margin-bottom: 2em;
        background: 0;
        font-size: 1.375rem;
      }

      .footer-action {
        background: none;
      }
    }

    .panel:first-of-type {
      border-top-left-radius: $radiusOuter;
      border-bottom-left-radius: $radiusOuter;
    }
  }

  &.-help {
    @include respond-to('mobile') {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  &.-panels {
    @include respond-to('mobile') {
      flex-direction: column;
      background: none;
      box-shadow: none;

      .panel {
        background: $baseLightColor;
        box-shadow: $shadowShallow;
        border-radius: $radiusOuter;
        margin-bottom: 2rem;

        &.-primary {
          background-color: $primaryLightColor;
          background-image: url('/assets/img/pattern.primary.png');
        }
        &.-secondary {
          background-color: $secondaryLightColor;
          background-image: url('/assets/img/pattern.secondary.png');
        }

        &.-primary,
        &.-secondary {
          background-size: 500px;
          color: $textColor;

          h2 {
            color: white;
            font-size: 2.75em;
          }
          p {
            padding: 0 1.5em;
          }
        }
      }
    }

    .tabs {
      width: 100%;
      left: 0;
      top: 0;
    }

    .footer-action {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
    }
  }

  .dismiss {
    position: absolute;
    top: 1em;
    right: 1em;
    background: none;
    border: none;
    z-index: 1;
    cursor: pointer;
  }

  h1 {
    @include h4();
  }

  .tabs {
    background-color: white;
    box-sizing: border-box;
    width: calc(100% + 4rem);
    position: relative;
    left: -2rem;
    top: -2rem;

    .tab {
      &:hover,
      &:focus {
        outline: 0;
        background-color: #dddada;
      }
    }
  }
}
