@import '../../_variables.scss';
@import '../../mixins/_grid.scss';
@import '../../mixins/_helpers.scss';

.client-list {
  @include columns(8, 2);
  margin-top: 2em;

  &__heading {
    @include columns(11, 0.5);
    margin-top: 2em;
    margin-bottom: 2em;
  }

  &__filter {
    @include columns(12);
    text-align: center;
    font-size: 2em;
    padding: 0.4em 1em;
    border: 0;
    border-radius: 0.5rem;

    &:focus {
      border: 0;
    }

    &::placeholder {
      color: $baseDarkColor;
    }
  }

  .card {
    padding: 1rem;
    display: flex;
    align-items: center;
  }

  &__image {
    flex: 0 0 auto;

    height: 3em;
    width: 3em;
    border-radius: 5em;
    border: 1px #00a694 solid;
    background-color: #bcc3ca;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  &__name {
    font-size: 1rem;
    margin: 0 0 0 1rem;
    flex: 1 1 1;
    color: $textColor;
    font-weight: 700;
  }

  &__empty {
    @include columns(12);
    margin-top: 0;
    text-align: center;
    background: #ffffff80;
    padding: 1em;
    border-radius: 0.5rem;
    font-style: italic;
    color: $textLightColor;
  }
}
