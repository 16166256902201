@import '../_variables.scss';

.subscription-status {
  display: inline-block;
  height: 3em;
  margin-right: 0.4em;

  &__badge {
    display: inline-block;
    border-radius: 2em;
    border: 1px solid $secondaryLightColor;
    color: $secondaryLightColor;
    background: lighten($secondaryLightColor, 35%);
    font-size: 0.775rem;
    padding: 6px 18px;
    align-self: center;
    margin: 11px 0;
    transition: 0.2s linear all;

    &:hover {
      border-color: $secondaryColor;
      color: $secondaryColor;
      background: $secondaryLighterColor;
      text-decoration: none;
    }

    &--icon {
      padding-left: 30px;
      position: relative;
      i {
        position: absolute;
        font-size: 1.25em;
        top: 4px;
        left: 8px;
      }
    }

    &-mini {
      @media screen and (min-width: 400px) {
        display: none;
      }
    }

    &-small {
      @media screen and (max-width: 400px) {
        display: none;
      }
      @media screen and (min-width: 480px) {
        display: none;
      }
    }
    &-large {
      display: none;
      @media screen and (min-width: 480px) {
        display: inline-block;
      }
    }
  }

  &--cancelled &__badge {
    border-color: $textLightColor;
    color: $textLightColor;
    background: $baseDarkColor;

    &:hover {
      border-color: $errorColor;
      color: $errorColor;
      background: lighten($errorColor, 55%);
    }
  }

  &--incomplete &__badge,
  &--hold &__badge {
    border-color: lighten($errorColor, 10%);
    color: lighten($errorColor, 10%);
    background: lighten($errorColor, 62%);

    &:hover {
      border-color: $errorColor;
      color: $errorColor;
      background: lighten($errorColor, 55%);
    }
  }
}
