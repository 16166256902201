/**
 * Application styling
 */
@import '~@bryntum/calendar/calendar.stockholm.css';
@import '../../assets/scss/mixins/_helpers.scss';
@import '../../assets/scss/_variables.scss';

.b-theme-stockholm {
  input {
    &:hover,
    &:focus {
      border: none;
    }
  }
}

.b-float-root {
  font-size: 12px;
}

.b-recurrenceconfirmationpopup.b-popup {
  max-width: 98vw;
}

.b-cal-event {
  transition: opacity 0.4s, box-shadow 0.4s;
}

.b-button label {
  margin: 0;
}

.b-calendar.b-outer {
  height: 700px;
}

.b-calendarrow .b-cal-cell-header.b-today {
  .b-day-name-date {
    background-color: $secondaryColor;
    color: #fff;
  }

  &:hover {
    .b-day-name-date {
      background-color: $secondaryLightColor;
    }
  }
}

.b-calendarrow .b-calendar-cell.b-nonworking-day .b-day-name {
  color: $secondaryColor;
}

.b-dayview-day-container .b-calendar-cell .b-current-time-indicator {
  border-top: 2px solid $secondaryColor;
}

.b-dayview-day-container .b-calendar-cell .b-current-time-indicator:before {
  background-color: $secondaryColor;
}

.b-sidebar {
  height: 690px;
  overflow: hidden;
}

.b-sidebar-content .b-datepicker-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-selected-date {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.b-calendar-view-desc {
  @include respond-to('tablet') {
    margin: 0 !important;
    min-width: 30vw;
  }
}

.b-event-time {
  @include respond-to('tablet') {
    font-size: 0.6rem;
  }

  @include respond-to('mobile') {
    font-size: 0.4rem;
  }
}

.b-cal-event-desc {
  font-size: 0.8rem;
}

.b-monthview .b-cal-event-bar-container,
.b-monthview .b-cal-cell-overflow {
  @include respond-to('tablet') {
    font-size: 0.8rem;
  }

  @include respond-to('mobile') {
    font-size: 0.6rem;
  }
}

.b-monthview .b-calendar-cell .b-week-num {
  @include respond-to('mobile') {
    display: none;
  }
}

.b-dayview-day-content {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 600px;
}

.b-dayview-day-container .b-event-header .b-event-time {
  font-size: 0.6rem;
}

.b-calendarrow .b-cal-cell-header .b-day-name-date {
  font-size: 1.2em;

  @include respond-to('mobile') {
    font-size: 1rem;
  }
}

.b-cal-agenda-date {
  @include respond-to('tablet') {
    flex: 0 0 12rem;
  }

  @include respond-to('mobile') {
    flex: 0 0 7rem;
  }
}

.cal-agenda-date-date-number {
  font-size: 2rem;

  @include respond-to('tablet') {
    font-size: 1.2rem;
  }

  @include respond-to('mobile') {
    font-size: 1rem;
    left: -5px;
    position: relative;
  }
}

.cal-agenda-date-date-text {
  font-size: 0.8rem;

  @include respond-to('mobile') {
    font-size: 0.6rem;
  }
}

.b-agendaview-body-wrap {
  .b-cal-agenda-event-time {
    @include respond-to('tablet') {
      flex: 0 0 12rem;
      font-size: 0.6rem;
    }

    @include respond-to('mobile') {
      flex: 0 0 5rem;
      font-size: 0.6rem;
    }
  }
}

.b-eventlist .b-grid-panel-body .b-cal-widget-settings-button {
  display: none;
}

.b-toolbar .b-button[data-ref='todayButton'] {
  @include respond-to('mobile') {
    display: none;
  }
}

.b-recurrencelegendbutton {
  margin-bottom: 8px;
}

.b-label {
  width: 90px;
  flex: 1 0 6em;
}

.b-calendar .b-sidebar-content .b-list {
  padding-bottom: 50px;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-blue .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-blue .b-cal-event .b-cal-recurrence-icon {
  color: #3183fe;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-cyan .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-cyan .b-cal-event .b-cal-recurrence-icon {
  color: #31e9fe;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-green .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-green .b-cal-event .b-cal-recurrence-icon {
  color: #27ca37;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-indigo .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-indigo .b-cal-event .b-cal-recurrence-icon {
  color: #4531fe;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-lime .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-lime .b-cal-event .b-cal-recurrence-icon {
  color: #76e62c;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-orange .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-orange .b-cal-event .b-cal-recurrence-icon {
  color: #feac31;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-pink .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-pink .b-cal-event .b-cal-recurrence-icon {
  color: #fe3183;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-purple .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-purple .b-cal-event .b-cal-recurrence-icon {
  color: #fe31ea;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-red .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-red .b-cal-event .b-cal-recurrence-icon {
  color: #e53f2c;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-teal .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-teal .b-cal-event .b-cal-recurrence-icon {
  color: #31feac;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-violet .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-violet .b-cal-event .b-cal-recurrence-icon {
  color: #ac31fe;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-yellow .b-cal-event .b-cal-event-icon,
.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar).b-cal-color-yellow .b-cal-event .b-cal-recurrence-icon {
  color: #ffeb3b;
}

.b-widget.b-floating.b-maximized,
.b-widget.b-positioned.b-maximized {
  left: 0;
  top: 0;
}

.b-float-root {
  .b-vbox {
    .b-panel-content {
      flex-grow: 0;
    }
  }
}
