//
// Navigation
// --------------------------------------------------

// Primary (Horiztonal) Navigation
// -------------------

ul[class*='menu-'] {
  margin: 0;
  padding: 0;

  li {
    position: relative;
    list-style: none;
    font-size: 1rem;
    text-align: left;

    a {
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      padding: 1em;
    }
  }
}

ul.menu-secondary {
  li {
    display: block;
    float: left;
    a {
      display: block;
      color: $textColor;
      padding: 1em 0.5em;
      margin: 0 0.5em;
      border: 2px transparent solid;
      &.-active {
        border-bottom: 2px $textColor solid;
      }
      &:hover,
      &:focus {
        border-bottom: 2px $primaryColor solid;
      }
    }
  }
}

$menutoggle-layer-width: 30px !default;
$menutoggle-layer-height: 2px !default;
$menutoggle-layer-spacing: 6px !default;
$menutoggle-hover-use-filter: false !default;
$menutoggle-hover-filter: opacity(50%) !default;

// menutoggle
// ==================================================
.menu-toggle {
  position: absolute;
  height: 0;

  padding: 0.8rem 1.6rem;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $menutoggle-hover-use-filter == true {
      filter: $menutoggle-hover-filter;
    } @else {
      opacity: 0.7;
    }
  }

  .box {
    width: $menutoggle-layer-width;
    height: $menutoggle-layer-height * 3 + $menutoggle-layer-spacing * 2;
    display: inline-block;
    position: relative;
  }

  .inner {
    display: block;
    top: 50%;
    margin-top: $menutoggle-layer-height / -2;

    &,
    &:before,
    &:after {
      width: $menutoggle-layer-width;
      height: $menutoggle-layer-height;
      background-color: $textColor;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      top: ($menutoggle-layer-spacing + $menutoggle-layer-height) * -1;
      // left: $menutoggle-layer-spacing;
      background-color: $textColor;
    }

    &::after {
      bottom: ($menutoggle-layer-spacing + $menutoggle-layer-height) * -1;
      // left: -$menutoggle-layer-spacing;
      background-color: $textColor;
    }
  }

  &.-active {
    height: 100vh;

    .inner {
      width: 0;
      &::before {
        transform: translate3d($menutoggle-layer-width * -0.3, 0.2em, 0)
          rotate(-45deg) scale(0.68, 1);
        top: -0.2em;
        left: $menutoggle-layer-spacing * 2;
      }

      &::after {
        transform: translate3d($menutoggle-layer-width * -0.3, -0.2em, 0)
          rotate(45deg) scale(0.68, 1);
        top: 0.2em;
        left: $menutoggle-layer-spacing * 2;
      }
    }
  }

  .text {
    display: inline-block;
    color: white;
    text-transform: uppercase;
    font-size: 0.7em;
    letter-spacing: 0.2em;
    padding: 0.25em 1em 0.25em 0;
  }
}
