@import '../../_variables.scss';
@import '../../mixins/_forms.scss';

$signup-column-half: calc(50% - 12px);

.invite-form {
  @include forms();
  &__name {
    flex: 0 0 $signup-column-half;
  }
}
.profile-completion {
  background: #f5f6f7;
  border-radius: 8px;

  margin: 0;
  padding: 20px;
  list-style-type: decimal;

  &__chip {
    border: 1px solid #40bdaf;
    &-container {
      background-color: #f5f6f7;
      margin-top: 70px;
      padding: 32px;
      width: calc(100% + 64px);
      margin-left: -32px;
      margin-right: -32px;
      margin-bottom: -32px;
    }
  }
  &__modal-title {
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }

  &__name-container {
    display: flex;
    flex-direction: row;
    div {
      width: 22px;
    }
  }
  label {
    margin-top: 20px;
    // margin-left: 26px;
    color: #0c1737;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    &:first-child {
      margin-top: 0;
    }
  }

  div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__box {
    background: #ffffff;
    border-radius: 6px;
    padding: 9px;
    margin-right: 12px;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    h4 {
      border: 1px solid #000000;
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 12px;
      font-size: 14px;
      font-weight: 400;
    }
    h5 {
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #0c1737;
      margin-right: 20px;
    }

    &:hover {
      background: $secondaryLighterColor;
    }
  }

  &__checked {
    background-color: #6bd6ca;
  }

  &__item {
    padding-left: 6px;
    margin-bottom: 0.6em;

    &:last-child {
      margin-bottom: 0;
    }

    a,
    del {
      color: $textColor;
      font-size: 1rem;
    }
  }
}
