@import '../../_variables.scss';
@import '../../mixins/_forms.scss';
@import '../../mixins/_helpers.scss';

$signup-column-half: calc(50% - 12px);

.signup-form {
  @include forms();

  &__name,
  &__country,
  &__postcode {
    flex: 0 0 $signup-column-half;
  }

  &__account,
  &__profile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
  }

  &__heading {
    display: block;
    width: 100%;
    margin-bottom: 2.5rem;
  }

  &__terms {
    margin-bottom: 2em;
  }

  &__terms &__error {
    color: $errorColor;
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 24px;
  }
}
