.loading-indicator {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 30em;

  img {
    width: 4em;
    height: 4em;
    padding: 0;

    position: absolute;
    top: 50%;
    left: 50%;

    margin: -2em 0 0 -2em;
  }

  h5 {
    position: absolute;
    top: 60%;
    left: 16px;
    right: 16px;
    text-align: center;
    color: #40bdaf;
  }
}
