@import '../_variables.scss';

.gallery {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  font-size: 0;
  visibility: hidden;
  opacity: 0;

  & + :not(.action) {
    margin-top: 16px;
  }

  -webkit-transition: all 0s linear;
  -moz-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;

  &.show {
    visibility: inherit;
    opacity: inherit;

    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
  }

  &-figure {
    overflow: hidden;
  }

  &-image,
  &-video {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: 0.3s filter $naturalEase;
    &:active {
      outline: none;
    }
  }

  &-video-loading {
    line-height: 2rem;
    font-size: 1rem;
    margin: 12px;
    border: 1px dashed $baseDarkColor;
    display: block;
    text-align: center;
    padding: 2rem;
  }
}
