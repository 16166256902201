.section-goals {
  background: $primaryLighterColor;
  padding: 2em 0;
  .goal-single {
    @include columns(12);
  }
}

.goal-single {
  background: white;
  border-radius: $radiusOuter;
  margin: 0 0 1em;
  .goal-header {
    padding: 1em 1em 0.5em;
    box-shadow: $shadowShallow;
    border-radius: 0 0 $radiusInner $radiusInner;
    @include clearfix();
    .title {
      float: left;
      margin: 0;
      h3 {
        margin: 0;
      }

      @include respond-to('mobile') {
        margin-top: 20px;
        width: 100%;
      }
    }
    .status {
      float: right;
    }
    .toggle {
      float: left;
      margin-right: 0.5em;
      margin-top: 0.1em;
    }
    .meta {
      float: left;
      display: flex;
      flex-direction: row;
      line-height: 1.375rem;
      .item {
        margin-left: 1em;
        font-weight: bold;
        &.-primary {
          color: $primaryColor;
        }
        &.-secondary {
          color: $secondaryColor;
          .icon-check {
            background: $secondaryLightColor;
            color: $textColor;
            height: 1.5em;
            width: 1.5em;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
          }
        }
      }

      @include respond-to('mobile') {
        margin-top: 20px;
        width: 100%;
      }
    }
    .description {
      display: flex;
      width: 100%;
      line-height: 2em;
    }
    .action {
      float: right;

      @include respond-to('mobile') {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
  .goal-steps {
    max-height: 80em;
    overflow: hidden;
    transition: max-height 200ms ease;
    position: relative;
    .step__draggable {
      cursor: grab;
    }
    .step {
      padding: 1.5em 1em;
      border-bottom: 1px $baseDarkColor solid;
      &:last-child {
        border-bottom: none;
      }
      .action {
        position: absolute;
        z-index: 10;
      }
      .content {
        padding: 0 0 0.5em 4em;
        position: relative;
        .title {
          margin: 0 2em 0.5em 0;
          line-height: 1.3;
        }
        .draggable {
          position: absolute;
          right: 0;
          height: 20px;
          width: 20px;
          top: 15px;
        }
        .meta {
          .item {
            &.-primary {
              color: $primaryColor;
            }
          }
        }
        .description {
          display: flex;
          width: 100%;
          background-color: blue;
        }
      }
    }
    .step-add {
      display: block;
      background: $baseColor;
      color: $textColor;
      text-decoration: none;
      cursor: pointer;
      padding: 0.5em 1em;
      height: 4rem;
      .action {
        position: absolute;
      }
      .content {
        padding: 0.8em 0 0.5rem 4em;
      }
    }
  }
  .goal-steps__hidden {
    display: none;
  }
  &.-complete {
    .title {
      // color:$textLightColor;
    }
    .goal-steps {
      max-height: 0;
    }
  }
}

.goal-details {
  background: white;
  border-radius: $radiusOuter;
  @include columns(12);
  margin: 0 0 3em;
  padding: 1em 1em 0.5em;
  box-shadow: $shadowShallow;
  border-radius: 0 0 $radiusInner $radiusInner;
  @include clearfix();
  .goal-steps-header {
    margin-top: 1em;
  }
  .goal-steps {
    border: 1px $baseDarkColor solid;
    border-radius: $radiusInner;
    margin-top: 1em;
    margin-bottom: 1em;
    .step__draggable {
      cursor: grab;
    }
    .step {
      padding: 1em 0;
      margin: 0 1em;
      border-bottom: 1px $baseDarkColor solid;
      &:last-child {
        border-bottom: none;
      }
      .action {
        position: absolute;
        z-index: 10;
      }
      .content {
        padding: 0 0 0.5em 4em;
        position: relative;
        .title {
          margin: 0 2em 0.5em 0;
          line-height: 1.3;
        }
        .draggable {
          position: absolute;
          right: 0;
          height: 20px;
          width: 20px;
          top: 15px;
        }
        .meta {
          .item {
            &.-primary {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
}

.goals-select {
  border: 1px solid $baseDarkColor;
  border-radius: $radiusOuter;
  margin: 0 0 1rem;
  .goals-select-goal {
    .goals-select-header {
      @include clearfix();
      padding: 1rem;
      cursor: pointer;
      .title {
        display: block;
        float: left;
        width: calc(100% - 24px);
        h3,
        h4 {
          margin: 0;
        }
      }
      .action {
        float: right;
      }

      &:hover,
      &.-open {
        background-color: $baseColor;
      }
    }

    .goals-select-steps {
      max-height: 0;
      overflow: hidden;
      transition: max-height 300ms ease;
      &.-open {
        max-height: 30em;
        border-bottom: 1px solid $baseDarkColor;
        overflow-y: auto;
      }
      .step {
        width: 100%;
        display: block;
        float: none;
        padding: 0.25rem 1rem 0.25rem;
        margin: 0 0 0.5rem;
        cursor: pointer;
        @include clearfix();
        .action {
          float: left;
          .icon-star {
            color: $primaryColor;
          }
          .icon-star-empty {
            color: $textLightColor;
          }
        }
        .content {
          float: left;
        }
      }
    }

    &:last-child {
      .goals-select-steps {
        &.-open {
          border-bottom: none;
        }
      }
    }
  }
}
