.comment-single {
  .comment-header {
    position: relative;
    padding: 0 0 0.5em 0;
    .-delete {
      float: right;
      color: $textLightColor;
      // top: 1em;
      // right: 1em;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $primaryColor;
      }
    }
    .profile {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      border-radius: 4em;
    }
    .author {
      display: inline-block;
      margin: 0.25em 0 0 0.5em;
      color: $textLightColor;
    }
    .time {
      display: inline-block;
      float: right;
      margin: 0 0.5em 0 0.5em;
      color: $textLightColor;
      font-size: 14px;
      text-align: right;
    }
    &.unread {
      background: $secondaryLighterColor;
      padding: 1em;
      // margin-bottom: 1em;
      margin: -1.3em -1em 1em -1em;
    }
  }
  .comment {
    padding: 0 0 1em 2em;
    margin: 0 0 0em;
    border-bottom: 1px $baseDarkColor solid;
    color: $textLightColor;
    line-height: 1.2rem;
  }
  .pre-new-line {
    white-space: pre-line;
  }
}
