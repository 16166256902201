/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
*/

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement,
.creditcard {
  display: block;
  padding: 0.75em;
  margin: 0 0 1em;
  width: 100%;
  max-width: 100%;
  border: 1px solid #e4e2e2;
  border-radius: 0.5em;
  outline: none;
  font-family: inherit;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;

  &-image {
    background-image: url('/assets/img/blank-creditcard.png');
    width: 24px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 24px 16px;
    display: inline-block;
  }
  &-number {
    padding-left: 1rem;
  }
  &-exp {
    float: right;
    padding-right: 1rem;
  }
  &-cvc {
    float: right;
    padding-right: 1rem;
  }
}
