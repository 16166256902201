@import '../../variables';

.complete {
  &__container {
    position: relative;
    margin: 0 16px;
    background-size: cover;
    border-radius: $radiusInner;

    &--invite {
      background-image: url('../../../img/confetti.png');
    }

    &--goal {
      background-image: url('../../../img/goal-complete.png');
    }

    &--profile {
      .media {
        background: 0;
      }
      img {
        border-radius: $radiusInner;
        background-clip: padding-box;
      }
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 64px 24px;
  }

  &__text-container {
    display: flex;
    height: 40px;
    border-radius: 20px;
    background-color: #f0d33d;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 18px;
    h4 {
      padding-top: 7px;
      color: #1383a4;
    }
  }

  &__text-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 6px;
  }

  &__name {
    font-family: Helvetica;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #1383a4;
    margin: 0;
  }

  &__steps-container {
    background: rgba(107, 214, 202, 0.1);
    border-radius: 6px;
    padding-top: 18px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 18px;
  }

  &__steps-header {
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__step-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    &-green {
      button {
        background: #6bd4c9;
      }
    }
    button {
      border: 1.5px solid #0c1737;
      box-sizing: border-box;
      height: 36px;
      width: 36px;
      border-radius: 18px;
      margin-right: 16px;
    }
  }

  &__step-text {
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.post-goal-tag {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-pressed,
  &-unpressed {
    width: 50%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    font-family: Helvetica;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;
  }

  &-pressed {
    color: $secondaryColor;
    background-color: #ffffff;
    font-weight: 700;
  }
  &-unpressed {
    color: $textLightColor;
    background-color: #f5f5f5;
  }
}

.goal-post {
  &__heading {
    padding: 12px 18px 6px 16px;
    color: $secondaryColor;
  }

  &:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid $borderLightColor;
  }
}

.goal-post-step {
  display: flex;
  align-items: baseline;
  padding: 10px 18px 10px 12px;
  cursor: pointer;

  &:hover {
    background: $secondaryLighterColor;
  }

  &__icon {
    flex: 0 0 22px;
    width: 22px;
    height: 22px;
    color: $secondaryColor;
    font-size: 22px;
    line-height: 22px;

    [class^='icon-']:before {
      margin: 0;
      padding: 0;
      width: 22px;
      height: 22px;
      line-height: 22px;
    }
  }

  &__label {
    flex: 1 1 auto;
    margin-left: 8px;
    margin-right: 16px;
  }

  &__checkbox {
    flex: 0 0 22px;
    width: 22px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button {
      margin: 0;
      width: 22px;
      height: 22px;
      line-height: 22px;
      &.-checkbox {
        border-color: $secondaryColor;
      }
      &.-complete {
        border-color: $textColor;
      }

      [class^='icon-']:before {
        top: -1px;
        position: relative;
        line-height: 22px;
      }
    }
  }
}
