.account-form {
  section {
    overflow: auto;
  }

  h4 {
    margin: 2rem 0 1em 0;
  }

  hr {
    margin: 2rem 0 1em 0;
  }

  .image {
    margin-bottom: 1em;
    // display: flex;
    // flex-direction: column;
    .img {
      display: inline-block;
      width: 7em;
      height: 7em;
      border-radius: 8em;
      background: $primaryLightColor;
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: auto;
      vertical-align: bottom;
      margin-bottom: 6px;
    }
    .button {
      margin-right: auto;
    }
  }

  &--editing {
    .StripeElement {
      width: 100%;
    }
  }

  input,
  textarea,
  .input {
    width: 100%;

    &.-half {
      width: 50%;
      float: left;
      clear: none;
      display: flex;
      padding-right: 0.5em;

      & + .-half {
        padding-right: 0;
        padding-left: 0.5em;
      }
    }
  }

  .section {
    clear: both;

    &.-movable {
      background: white;
    }
    &.-separated {
      border-top: 1px solid $baseDarkColor;
      padding: 2em 0;
    }
    &.-centered {
      text-align: center;
    }

    &.-steps {
      background: $baseColor;
      border: 1px solid $baseDarkColor;
      border-radius: $radiusInner;
      padding: 1em;
      .step {
        padding-bottom: 2em;
      }
    }

    &.creditcard {
      width: 80%;
      float: left;
    }

    .section-actions {
      .button {
        margin: 0 1em 0 0;
      }
    }
  }

  .remove-button {
    font-weight: bold;
    float: right;
    color: $textColor;
  }
}
