.profile-header {
  background: $tertiaryColor;
  color: white;
  padding: 4em 2em 2em 2em;
  margin: -4rem 0 0;
  @include respond-to('mobile') {
    padding-top: 2em;
  }
  .image {
    @include columns(2);
    position: relative;
    .profile-image {
      width: 9em;
      height: 9em;
      border-radius: 9em;
      background: $primaryLightColor;
      // border: 2px $primaryColor solid;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      right: 0;
      top: 0em;
      z-index: 1;
      @include respond-to('mobile') {
        position: relative;
        right: auto;
        width: 6em;
        height: 6em;
      }
    }
  }
  .name {
    @include columns(10);
    padding: 2em 0 0 1rem;
    font-weight: bold;
    @include respond-to('mobile') {
      padding-top: 1em;
      h1 {
        font-size: 2rem;
      }
    }
    h3 {
      margin-bottom: -1rem;
      margin-top: 1rem;
    }
  }
}

.profile-nav {
  background: $baseColor;
  border-radius: 0 0 $radiusOuter $radiusOuter;
  margin-bottom: -$radiusOuter;
  position: relative;
  z-index: 2;
  margin: 0 0 2em;
  position: sticky;
  z-index: 0;
  .menu-secondary {
    @include columns(10, 2);
  }
}

.page.-profile {
  margin-bottom: 4rem;

  &.-edit {
    .aside {
      background: transparent;
      box-shadow: none;
    }
  }
  &.-about,
  &.-goals,
  &.-team {
    .aside {
      @include respond-to('mobile') {
        display: none;
      }
    }
  }
}

.subscription-form {
  .profiles-select {
    width: inherit;
    height: inherit;
  }
}

.profiles-select {
  height: 3em;
  width: 3em;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &.-open {
    z-index: 10;
    .notification-wrap {
      background: $secondaryLighterColor;
      border: 1px solid $secondaryLightColor;
      color: $secondaryColor;
    }
  }

  .profiles-single {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none inside none;
    background: white;
    display: block;
    text-align: left;
    cursor: pointer;

    &.-toggle {
      background: transparent;
      padding: 0;
      width: 100%;
      height: 100%;

      &.tab {
        padding: 1rem;
        position: absolute;
        width: 100%;
        left: 0;
        background: white;

        &:first-child {
          z-index: 990;
        }

        &.show {
          z-index: 1000;
        }
      }
    }

    .tab {
      width: 100%;
      background: white;
    }

    .image {
      position: absolute;
      top: 0.75em;
      left: 0.5em;
      height: 26px;
      width: 26px;
      border-radius: 5em;
      border: 1px darken($baseColor, 20%) solid;
      background-color: darken($baseColor, 20%);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      &.-icon {
        background-color: $secondaryLightColor;
        border-color: $secondaryLightColor;
        color: white;
        text-align: center;
        padding-top: 4px;
      }
    }

    a {
      display: block;
      padding: 1em 1em 1em 2.5em;
    }
  }
  &.-open {
    &.tabs {
      .list {
        width: 100%;
        padding: 0;
        margin: 0;
        right: 0;
        top: 3rem;
      }
      &:before {
        display: none;
      }
    }

    .list {
      display: block;
      background: white;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 1rem;
      top: 2.5rem;
      height: 0;
      width: 0;
      border-width: 0.5rem;
      border-color: transparent transparent white transparent;
      border-style: solid;
      box-sizing: border-box;
      z-index: 8;
    }
  }
  .list {
    display: none;
    position: absolute;
    width: 15em;
    top: 3.5rem;
    right: 5px;
    margin: 0;
    padding: 0;
    z-index: 5;
    box-shadow: $shadowDeep;
    border-radius: $radiusInner;
    overflow: hidden;

    max-height: 80vh;
    overflow-y: auto;

    .profiles-single {
      background: white;
      a {
        color: $textColor;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      &:hover,
      &:focus {
        background: $secondaryLighterColor;
      }
      &.-create {
      }
      &.-settings {
      }
      &.-logout {
        display: flex;
        border-top: 1px solid $borderLightColor;
        cursor: pointer;
        margin-top: 6px;
        button {
          width: 100%;
          height: 48px;
          cursor: pointer;
          text-align: left;
          padding-left: 11px;
          font-size: 16px;
          color: $textLightColor;
          line-height: 48px;

          span {
            padding-right: 13px;
          }
        }
      }
      &:first-child {
        border-radius: $radiusInner $radiusInner 0 0;
      }
      &:last-child {
        border-radius: 0 0 $radiusInner $radiusInner;
      }
    }
  }
}

.profile-aside .profile-completion {
  padding-left: 18px;

  &__item {
    del,
    a {
      color: $secondaryColor;
    }
  }
}
