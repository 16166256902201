@import '../_variables.scss';
@import '../mixins/_helpers.scss';

.panel {
  flex: 1 0 50%;
  padding: 2rem 2rem 1.5rem 4rem;

  @include respond-to('mobile') {
    padding-top: 2rem;
  }

  &.-content {
    @include respond-to('mobile') {
      padding-top: 0;
    }
  }

  @include respond-to('mobile') {
    padding: 1.5rem 2rem;
  }

  &.-primary {
    background-color: $primaryLightColor;
    background-image: url('/assets/img/pattern.primary.png');

    @include respond-to('mobile') {
      background: 0;
    }
  }
  &.-secondary {
    background-color: $secondaryLightColor;
    background-image: url('/assets/img/pattern.secondary.png');

    @include respond-to('mobile') {
      background: 0;
    }
  }

  &.-third {
    flex: 1 0 33%;
  }

  &.-two-third {
    flex: 1 0 67%;
  }

  &.-compact {
    padding: 2rem;
  }

  &.-primary,
  &.-secondary {
    background-size: 500px;
    color: $textColor;

    h2 {
      color: white;
      font-size: 2.75em;
      margin-bottom: 1em;

      @include respond-to('mobile') {
        color: $textColor;
        font-size: 2em;
      }
    }
    h3 {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  }

  p:not(.MuiTypography-root) {
    margin-bottom: 2rem;
    line-height: 1.4em;
  }

  ol,
  ul {
    margin-top: 2.5em;
    padding-left: 1em;
  }

  li {
    margin-bottom: 2em;
  }

  .article-errors {
    left: -4em;
    top: 1.5rem;
    width: calc(100% + 6rem);
    border-bottom-left-radius: 0;
  }
}
