.user-single {
  @include columns(12);
  background: white;
  padding: 0 1em;
  margin: 0 0 1em;
  border-radius: $radiusInner;
  position: relative;

  &.-selected {
    background: #f2f8fa;
    color: #1c8eae;
    .profile {
      .image {
        //
        border: 2px #1c8eae solid;
      }
    }
  }

  .profile {
    position: absolute;
    left: 1em;
    top: 1em;
    .image {
      display: inline-block;
      width: 1.8em;
      height: 1.8em;
      border-radius: 4em;
      background-size: cover;
      background-color: $baseDarkColor;
      border: 2px solid transparent;
    }
    // img {
    // 	display:inline-block;
    // 	width:1.8em;
    // 	height:1.8em;
    // 	border-radius:4em;
    // 	background:$baseDarkColor;
    // }
  }

  .details {
    padding: 1em 0 1em 3em;
    h4 {
      margin: 0 0 0.25em;
    }
    p {
      margin: 0;
      font-size: 0.8em;
      color: $textLightColor;
    }
  }

  .action {
    position: absolute;
    right: 1em;
    top: 1em;
    width: 4em;
    text-align: right;
  }
}
