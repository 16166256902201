@import '../variables.scss';

// --------------------------------------------------
// Tabs
// --------------------------------------------------

.tabs {
  width: calc(100%);
  position: relative;
  display: flex;
  .tab {
    display: block;
    float: left;
    padding: 1rem;
    border-bottom: 2px darken($baseColor, 10%) solid;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.1em;
    color: $textLightColor;
    flex-grow: 1;
    &:hover,
    &:focus {
      background: $secondaryLighterColor;
      color: $textColor;
      text-decoration: none;
    }
    &.-active {
      color: $textColor;
      border-bottom-color: $primaryColor;
    }
  }
}
