.page-error {
  text-align: center;
  margin-top: 3em;
  h1 {
    margin-bottom: 2rem;
  }
  .actions {
    margin: 1rem 0;
  }
}

.section-empty {
  text-align: center;
  margin: 1em auto;
  color: $textLightColor;
}
