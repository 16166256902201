.section-notifications {
  // max-width: 300px;
  background: #fff;
  border-radius: 8px;
  .section-notifications-header {
    padding: 1.5rem 1rem 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 0;
    }
    span {
      color: $primaryColor;
      font-weight: 700;
      font-size: 11px;
    }
  }
  .notifications-filter {
    padding: 1rem 1rem 0 1rem;
    label {
      font-size: 10px;
      margin-bottom: 0.75rem;
      font-weight: 500;
    }
    select {
      height: 35px;
      padding: 0.5rem 0.75rem;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
    .select {
      margin: 0 0 1.25em;
    }
  }
}

.empty {
  padding: 0 1rem;
  margin-bottom: 1.5rem;
  .empty-inner {
    background: $secondaryLighterColor;
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    span {
      font-weight: 700;
      color: $secondaryColor;
      width: 50%;
      display: inline-block;
      font-size: 18px;
      line-height: 1.3;
    }
    img {
      width: 90px;
    }
  }
}

.notification-single {
  background: $secondaryLighterColor;
  padding: 1rem 1rem 0 1rem;
  // max-width: 300px;
  cursor: pointer;
  transition: ease 0.2s all;
  a {
    color: inherit;
    text-decoration: none;
  }
  &:last-child {
    padding-bottom: 1rem;
    hr {
      display: none;
    }
  }
  &:hover,
  &:focus {
    background: lighten($secondaryLightColor, 32%);
    transition: ease 0.2s all;
  }
  hr {
    margin: 0;
    border-top: 0;
    width: 100%;
    border-bottom: 1px solid $borderLightColor;
    padding-top: 1rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.7rem;
  }
  .avatar {
    width: 30px;
    height: 30px;
    background: lighten($primaryColor, 32%);
    border: 1px solid $primaryColor;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 10px;
    }
    img {
      width: 28px;
      border-radius: 100%;
      border: none;
    }
  }
  .action {
    font-size: 0.9rem;
    line-height: 1.2;
    font-weight: 100;
    span {
      font-weight: 700;
    }
  }
  .reaction {
    font-size: 0.9rem;
    line-height: 1.2;
    color: $textColor;
    font-weight: 200;
    margin-top: 0.5rem;
  }

  .header,
  .text {
    color: $textColor;
  }
  .text {
    white-space: pre-wrap;
  }
  .time {
    margin: 0;
    font-size: 11px;
    font-weight: 700;
    color: $secondaryColor;
  }
}

.notification-holder {
  display: inline-block;
  position: relative;
  &.-active {
    .notification-wrap {
      border: 1px solid $secondaryLightColor;
      background: $secondaryLighterColor;
    }
    .notification-icon {
      color: $secondaryColor;
    }
  }

  .menu-notifications {
    position: absolute;
    border-radius: 10px;
    z-index: 9;
    top: 3.75rem;
    right: 0px;
    background: white;
    box-shadow: $shadowDeep;
    padding: 0;
    width: 20rem;
    height: 0;
    max-height: 0;
    overflow-y: auto;
    transition: all 300ms ease;
    .notification-settings {
      font-size: 0.8rem;
      text-align: right;
      margin: 0;
      a {
        color: $textLightColor;
        &:hover,
        &:focus {
          color: $textColor;
          text-decoration: underline;
        }
      }
    }

    .more {
      padding: 1rem;
      text-align: center;
      color: $textLightColor;
    }
  }

  &.-active {
    .menu-notifications {
      max-height: 80vh;
      height: auto;
    }
  }
}

.notification-wrap {
  width: 26px;
  height: 26px;
  border: 1px solid darken($baseColor, 20%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: ease 0.2s all;

  &:hover {
    border: 1px solid $secondaryLightColor;
    transition: ease 0.2s all;
  }
}

.notification-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 2.25rem;
  height: 3rem;
  font-size: 1.3rem;
  line-height: 3rem;
  position: relative;
  color: $textLightColor;
  .notification-number {
    background-color: $secondaryColor;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    font-size: 0.6rem;
    line-height: 1.5rem;
    color: white;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: 0.7em;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover,
  &:focus {
    color: $secondaryColor;
    cursor: pointer;
    outline: 0;
    .notification-number {
      background-color: $secondaryColor;
      color: #fff;
    }
  }
}
