.primary-navigation {
  padding: 0;
  position: fixed;
  z-index: 2002;
  width: 100%;
  top: 0;
  margin: 0 0 2rem;

  .container {
    display: flex;
    flex-direction: row;
    position: relative;
    background: $baseLightColor;
    height: 3rem;
    box-shadow: $shadowDeep;
    border-radius: 0 0 $radiusOuter $radiusOuter;
  }

  .menu-user {
    padding: 0.8em 0;
    margin-left: 1em;
    @include respond-to('mobile') {
      padding: 0.7em 0;
    }
    .button {
      margin-right: 1em;
      margin-bottom: 0px;
      font-size: 0.8em;
      width: auto;
      @include respond-to('mobile') {
        padding: 0.625em 1.5em;
      }
    }
  }

  .account {
    display: flex;
    align-items: center;
    flex: 0 0 1;
    height: 100%;

    .status-bar {
      .account-icon {
        display: inline-block;
        padding: 0.5rem;
        margin-right: 0.5em;
        width: 2.5em;
        height: 2.5em;
        position: relative;
        list-style: none inside none;
        background: white;
        margin-top: auto;
        margin-bottom: auto;
        text-align: left;
        cursor: pointer;
        .image {
          height: 2rem;
          width: 2rem;
        }
        .image {
          display: inline-block;
        }
        .icon {
          display: none;
          i:before {
            padding: 0 0 0 0;
            width: 3rem;
            height: 3rem;
            font-size: 1.3rem;
            line-height: 3rem;
            position: relative;
            color: $textLightColor;
          }
        }
      }
    }
  }

  .brand {
    flex: 1;
    margin: 0 14px;

    img {
      padding: 0.5em 0;
      position: relative;
      display: inline-block;
      max-width: 10em;
      height: auto;
      &.-inverse {
        display: none;

        &.-mini {
          display: none;
        }
      }
      &.-mini {
        display: none;

        @include respond-to('mobile-p') {
          display: inline-block;
          height: 47px;
          width: auto;
        }
      }
      @include respond-to('mobile') {
        width: 8em;
        padding: 0.6em 0;
      }

      @include respond-to('mobile-p') {
        display: none;
      }
    }
    a {
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  &.-inverse {
    .brand {
      img {
        display: none;
        &.-inverse {
          display: inline-block;
        }
      }
    }
  }
}

.menu-underlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.menu-container {
  display: block;
  position: fixed;
  top: -100vh;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  background: $primaryLighterColor;

  // padding:5rem 3rem ;
  transition: top 600ms ease-in-out;
  &.-active {
    top: 0;
  }
}
