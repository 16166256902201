.heading {
  width: calc(100% + 4rem);
  position: relative;
  left: -2rem;
  top: -2rem;
  padding: 1.5rem 2rem;
  text-align: center;
  border-radius: $radiusOuter $radiusOuter 0 0;
  background-color: $baseColor;
  background-repeat: repeat;
  &.-primary {
    background-color: $primaryLightColor;
    background-image: url('/assets/img/pattern.primary.png');
    color: $textColor;
  }
  &.-secondary {
    background-color: $secondaryLightColor;
    background-image: url('/assets/img/pattern.secondary.png');
    color: $textColor;
  }
  &.-modal {
    top: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }
  // .button.-right {
  //   position: absolute;
  //   right: 2rem;
  // }
}
