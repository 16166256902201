.page.-profile {
  .card {
    &.-title {
      background: transparent;
      padding: 0;
      box-shadow: none;
      margin-bottom: 1rem;
      h1,
      h2,
      h3 {
        margin-bottom: 1rem;
      }
    }
  }
}
