@import '../_variables';

.progress-bar {
  border: 1px solid $secondaryLightColor;
  height: 6px;
  border-radius: 3px;

  &__progress {
    background: $secondaryLightColor;
    height: 100%;
    width: 0px;
    transition: 0.5s linear width;
  }
}
