@import '../_variables.scss';
@import '../mixins/_helpers.scss';

.help-panel {
  position: relative;
  height: 100%;
  background: $baseColor;
  overflow-y: auto;
  flex: 0 0 42px;
  max-width: 500px;
  @media screen and (max-width: 740px) {
    padding-top: 32px;
    z-index: 100;
  }
  transition: background-color 0.3s $naturalEase, max-width 0.3s $naturalEase, flex-basis 0.3s $naturalEase, margin-right 0.3s $naturalEase;

  @include respond-to('mobile') {
    overflow: visible;
    max-width: 0;
  }

  &--open {
    flex: 0 0 50%;

    @include respond-to('mobile') {
      flex-basis: 100%;
      max-width: 100%;
      overflow-y: auto;
    }
  }

  &__content {
    width: 100%;
    padding: 2em;

    .profile-completion__box {
      width: fit-content;
      margin: 0 auto 10px auto;

      h4,
      h5 {
        display: inline-block;
      }

      svg {
        margin: 2px 5px 0 5px;
      }
    }

    li {
      margin-bottom: 0.5em;
    }

    @include respond-to('mobile') {
      padding: 2em 0.5em;
    }
  }

  &__button {
    border: none;
    position: absolute;
    padding: 0.35em 0.25em;
    right: 0;
    font-size: 1.35em;
    color: $textLightColor;
    outline: none;
    cursor: pointer;

    @include respond-to('mobile') {
      right: -42px;
    }
  }

  &--open &__button {
    @include respond-to('mobile') {
      right: 0;
    }
  }
}
