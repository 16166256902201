.progress {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
  padding-bottom: 22px;
  &__line-done {
    border: 1px solid #40bdaf;
    height: 0px;
    width: 96px;
  }
  &__line {
    border: 1px solid #bbbbbb;
    height: 0px;
    width: 96px;
  }
  &__circle {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    background: rgba(220, 220, 220, 0.28);
    border: 2px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
    p {
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: #bbbbbb;
      position: absolute;
      margin-top: 110px;
    }
  }
  &__circle-done {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    background: rgba(107, 214, 202, 0.28);
    border: 2px solid #40bdaf;
    box-sizing: border-box;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    p {
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: #40bdaf;
      position: absolute;
      margin-top: 110px;
    }
  }
}
