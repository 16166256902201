@import '../_variables.scss';

.avatar {
  .image {
    border-radius: 50%;
    border: 1px $secondaryColor solid;
    background-color: darken($baseColor, 20%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}
