@import '../_variables.scss';

@mixin forms() {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  &__label {
    font-weight: 600;
    line-height: 1.5em;
    transition: 0.2s linear color;
    flex: 1 1 100%;

    .file-upload {
      width: 100%;
    }
  }

  &__section-heading {
    flex: 1 1 100%;
  }

  small {
    color: white;
    transition: 0.2s linear all;
  }
  input {
    transition: 0.2s linear border-color;
  }

  &__invalid {
    color: $errorColor;
    input {
      border-color: $errorColor;
    }
    small {
      color: $errorColor;
    }
  }

  &__focus {
    color: $textColor;
    input {
      border-color: $baseDarkColor;
    }
    small {
      color: $textLightColor;
    }
  }

  &__required,
  &__optional {
    font-weight: 200;
    font-size: 10px;
    color: #aaa;
    margin-left: 6px;
  }

  &__image {
    width: 96px;
    height: 96px;
    background-size: cover;
    border-radius: 6px;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .button.-right {
    // margin-left: auto;
  }

  .react-datepicker {
    &-wrapper {
      display: block;
    }
    &__input-container {
      display: block;
    }
    &-popper {
      z-index: 10;
    }
  }
}
