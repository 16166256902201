@import '../../variables.scss';
@import '_abstract.scss';

.invite-type {
  &__heading {
    text-align: center;
    font-weight: 600;
    margin-bottom: 42px;
  }

  &__selection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;

    @include respond-to('mobile') {
      flex-wrap: wrap;
    }
  }

  &__type {
    flex: 1 0 calc(33% - 42px);
    margin: 24px;
    cursor: pointer;

    @include respond-to('mobile') {
      margin: 0;
    }
  }

  &__description {
    font-size: 1.3em;
    min-height: 4.2em;
  }

  &__img-wrap {
    position: relative;
    border-radius: 100%;
    border: 4px solid $baseDarkColor;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    height: 10rem;
    width: 10rem;
    margin: auto;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    @include respond-to('mobile') {
      height: 100px;
      width: 100px;
      margin: auto;
    }
  }

  &__img {
    filter: grayscale(1);
    opacity: 0.5;
    transition: 0.3s ease-out all;
    transform: scale(0.8);
    width: 12.5rem;
  }

  &__label {
    padding-top: 24px;
    text-align: center;
    font-weight: 600;
    color: $baseDarkColor;
    transition: 0.3s ease-out all;
    display: block;
  }

  &__type--selected &__img {
    filter: none;
    opacity: 1;
    transform: scale(0.9);
  }

  &__type--selected &__label {
    color: $textColor;
  }
}
