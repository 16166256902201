@import '../_variables.scss';

.page {
  .file-browser {
    margin-top: 10px;
  }

  .chonky-chonkyRoot {
    border: 0;
    padding: 0 1em 0.5em 1em;
  }

  div[class^='gridFileEntryName'] {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1rem;
  }
}

.owner-documents {
}

.team-documents {
}
