.user-onboard {
  &_video {
    display: flex;
    justify-content: center;
  }

  &__actions {
    margin-top: auto;
  }

  &__heading {
    font-size: 1.2rem;

    .panel.-secondary & {
      font-size: 2.1rem;
      margin-bottom: 1rem;
    }
  }

  &__panel {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 1rem 2rem;
  }

  .profile-completion {
    margin-bottom: 1rem;
  }

  &__section {
    margin-bottom: 2rem;
  }
}
