@import '../../_variables.scss';
@import '../../mixins/_helpers.scss';
@import '../../mixins/_forms.scss';

$invite-column-half: calc(50% - 12px);

%invite-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
}

%invite-form__description {
  flex: 0 0 100%;
  text-align: right;
}

%invite-form__radio {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

%invite-form--selected {
  opacity: 1;
}
