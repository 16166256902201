@import './_variables.scss';

@mixin print {
  .print {
    display: block !important;
  }

  .screen {
    display: none !important;
  }

  .footer {
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;
    font-size: 7.5pt !important;
    padding: 5px;
  }

  .footer-link {
    position: relative;
    left: -7px;
    margin: 0;
    padding: 0;
    display: inline;
    font-size: 7.5pt !important;
  }

  .container {
    margin: 0 !important;

    hr {
      border-top: 1px solid $primaryColor;
    }

    input,
    textarea {
      border: none;
      padding: 0;
      color: $textLightColor;
      border-radius: 0;
    }

    .aside {
      display: none;
    }

    .card {
      float: none;
      clear: both;
      border-radius: inherit;
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;

      &__content {
        margin-top: 0;
      }

      .card__body {
        width: 100%;
        margin-top: 0;
      }

      & .sticky_top {
        box-shadow: none;
        position: absolute;
        background-color: $tertiaryColor;
        top: 0;
        width: 100%;
      }

      .goal-header {
        .description {
          font-size: 9pt;
          line-height: 10.5pt;
        }

        .title {
          margin: 0;
          padding-bottom: 6px;
        }
      }

      &.-sections {
        .heading {
          background-color: $tertiaryColor;
          color: white;
          text-align: left;
          font-size: 18pt;
          line-height: 18pt;
          padding: 2em 0 1.2em 134px;
        }
      }

      .help-panel {
        display: none;
      }

      .tabs {
        display: none;
      }

      .footer-action {
        display: none;
      }

      .footer-menu {
        margin: 0;

        a,
        strong {
          margin: 0;
          padding: 0;
        }
      }
      .button {
        display: none;
      }
      .required {
        display: none;
      }

      .logo {
        top: -4px;
        width: 160px;
        float: right;
        position: relative;
        margin-right: 32px;
      }

      .profile.avatar.image {
        top: 38px;
        left: 32px;
        position: absolute;
        width: 86px;
        height: 86px;
        border: 1px solid $secondaryColor;
        background-position: center;
        border-radius: 43px;
        z-index: 1000;

        img {
          border-radius: 43px;
        }
      }

      .profile-form {
        &__label {
          font-size: 9pt !important;
          color: $textLightColor;
          flex-basis: calc(50% - 12px);
          textarea,
          input,
          p {
            font-size: 9pt !important;
            line-height: 12pt;
            color: $textColor;
            padding-bottom: 16px;
            font-weight: normal;
            margin: 0;
          }
        }
      }

      .section-feed {
        background-color: white;
        margin: 0;
        padding: 0;
        float: none;
        width: 100%;

        h3 {
          font-size: 10.5pt;
          font-weight: 600;
          padding-bottom: 12px;
        }

        .feed-title {
          float: none;
          margin: 0;
        }

        .container > div {
          border-top: 1px solid $borderLightColor;

          &--first,
          &:first-child {
            border-top: 0;
          }
        }

        .post-single {
          .post {
            margin: 0;
            box-shadow: none;
            float: none;
            width: 100%;
            position: relative;
            border-radius: 0;
            padding-bottom: 8px;
            display: flex;
            flex-wrap: wrap;

            &.unread {
              background: none;
              margin: 0;
            }

            &__header {
              padding: 14px 4px 12px 4px;
              flex: 0 0 100%;
            }

            &__title {
              margin-left: 0;
              font-size: 9pt;

              a {
                font-size: 9pt;
              }
            }

            &__time {
              padding: 14px 4px 4px 4px;
              font-size: 9pt;
            }

            &__delete {
              display: none;
            }

            .user-link {
              margin-right: 4px;
            }

            .action {
              display: none;
            }

            .avatar {
              display: none;
            }

            .text {
              color: $textLightColor;
              font-size: 9pt;
              line-height: 12pt;
              padding: 4px;
            }

            .meta {
              display: flex;
              flex: 0 0 120px;
              padding: 0;
              align-items: center;
              margin-left: auto;
              justify-content: space-between;

              .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 0 0 28px;
                margin: 0;

                span {
                  padding-left: 6px;
                  display: block;
                  width: fit-content;
                  flex: 1;
                }

                &.-time {
                  display: none;
                }

                i {
                  display: flex;
                  align-items: center;
                  flex: 0 0 16px;
                  width: fit-content;

                  &.icon-star {
                    position: relative;
                    left: -12px;
                  }

                  &.icon-thumbs-up {
                    position: relative;
                    left: -6px;
                  }

                  &::before {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
      }

      .profile-form {
        &__section {
          margin-top: 0;
          padding-bottom: 32px;
          page-break-inside: avoid;
          page-break-before: auto;

          &.essentials {
            padding-top: 140px;
          }
        }

        &__error,
        &__label_required {
          display: none;
        }

        &__invalid {
          color: black !important;
        }

        &__avatar {
          display: none;
        }
      }

      .section-team {
        background: none;
        padding-bottom: 0;

        h3 {
          border-bottom: 1px solid $primaryColor;
          padding-bottom: 14px;
          margin-left: -1%;
        }

        h4 {
          color: black;
          font-weight: 600;
          font-size: 9pt;
        }

        .team-heading {
          padding-top: 6rem;
          margin: 0;
          width: 100%;
        }

        .team-heading:first-child {
          padding-top: 0;
        }
        .team-users {
          margin: 0;
          font-size: 9pt;

          .user-card {
            font-size: 9pt;
            box-shadow: none;
            border-bottom: 1px $baseDarkColor solid;
            margin: 0;
            border-radius: 0;

            &__appointment {
              display: none;
            }

            &__relationship {
              font-size: 7.5pt;
              line-height: 9pt;
            }

            &__invite_accepted,
            &__invite_sent,
            &__permission {
              font-size: 6pt;
              line-height: 7.5pt;
              margin: 0;
              text-align: center;
            }

            &__avatar {
              margin: 0;
              padding: 12px 0;
            }

            .profile.avatar .image {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .profile-goal {
        padding-bottom: 48px;

        &:not(.profile-goal--first) .goal-header > .title {
          display: block;
          float: none;
          clear: both;
          width: 100%;
          padding-top: 14px;
          border-top: 1px solid $secondaryColor;
        }
      }

      .complete {
        font-size: 9pt;
        &__container {
          width: 100%;
        }

        &__text-label {
          font-size: 10.5pt;
        }

        &__name {
          font-size: 12pt;
        }

        &__steps-header {
          font-size: 9pt;
          margin-top: 0;
        }

        &__step-text {
          font-size: 9pt;
        }

        &__step-container {
          button {
            display: flex;
            width: 24px;
            height: 24px;
            margin: 0;
            margin-right: 8px;
            align-items: center;
            justify-content: center;
            position: relative;

            i {
              display: flex;
              align-items: center;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 2px;
              left: -11px;

              &::before {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }

      .goal {
        &-single {
          color: $textLightColor;
          margin: 0;

          .toggle {
            display: none;
          }

          .step-add {
            display: none;
          }

          .step {
            padding: 0;

            .content {
              padding: 0.5rem 0 0 3rem;
            }
          }

          .item {
            display: none;
          }

          .goal {
            &-steps {
              display: none;
            }

            &-header {
              display: inherit;
              padding-left: 0;
            }
          }
        }

        &-header {
          box-shadow: none;
          display: none;

          h3 {
            color: black;
            font-weight: 600;
            font-size: 10.5pt;
          }

          .action {
            display: none;
          }
        }

        &-details {
          margin: 0;
          &-steps {
            display: block;
          }

          .description {
            font-size: 9pt;
            line-height: 12pt;
          }
        }

        &-steps {
          .content {
            display: flex;
            padding: 0 0 0 12px;
            align-items: center;
            justify-content: space-between;
            flex: 1;

            .title {
              font-weight: 600;
              font-size: 9pt !important;
              line-height: 12pt;
              color: $textLightColor;
              margin: 0;
            }
          }

          .step {
            display: flex;
            padding: 0;
            margin: 0;
            align-items: center;
          }

          .action {
            position: initial;
            padding: 12px 0;
          }

          .meta {
            display: flex;
            margin-left: auto;
            flex: 0 0 32px;
            justify-content: flex-end;

            .item {
              display: flex;
              align-items: center;
            }

            i {
              display: flex;
              align-items: center;
              position: relative;
              top: -1px;

              &::before {
                width: 14px;
                height: 14px;
              }
            }
          }

          .button {
            display: flex;
            width: 24px;
            height: 24px;
            margin: 0;
            align-items: center;
            justify-content: center;
            position: relative;

            i {
              display: flex;
              align-items: center;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 2px;
              left: -13px;

              &::before {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        &-details {
          display: block;
          float: none;
          width: 100%;
          margin: 0;
          padding: 0 0 16px 0;
          box-shadow: none;

          h4 {
            font-size: 10.5pt;
          }

          .goal {
            &-steps {
              border: none;
              margin: 0;
              border-radius: 0;

              .step {
                padding: 0;
              }
            }
          }
        }
      }

      .gallery {
        margin-bottom: 0px;

        .gallery-figure {
          height: 120px;
          display: inline-block;
          min-width: inherit !important;
          max-width: 19% !important;
          flex: none !important;
          padding: 6px;
          page-break-inside: avoid;
          page-break-before: auto;
        }
      }
    }
  }
}

.k-pdf-export-wrapper {
  position: absolute;
  left: -10000px;
  top: 0;
  overflow-y: scroll;
  height: 297mm;
  width: 210mm;
  font-size: 9pt;

  .k-pdf-export > .container {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  @include print;
}

@media print {
  @include print;
}
