.section-team {
  background: $primaryLighterColor;
  padding: 0 0 2em 0;

  .team-users {
    margin-bottom: 32px;
    overflow: hidden;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    .user-single {
      .profile {
        .image {
          width: 2.4em;
          height: 2.4em;
        }
      }
      .details {
        padding-left: 3.4em;
      }
      .appointment {
        position: absolute;
        top: 0;
        left: 50%;
        p {
          font-size: 0.7em;
          margin: 1.2em 0 0.2em 0;
          color: $textLightColor;
        }
      }
      .action {
        .button.-toggle {
          margin: 0;
          border: 0;
          background: transparent;
          width: 2.2em;
          height: 2.2em;
          font-size: 1.4em;
          &:hover {
            color: $primaryLightColor;
          }
        }
      }
    }
  }

  .team-heading {
    @include columns(12);
    margin-bottom: 1rem;
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }
  }
}

.team-select {
  border: 1px solid $baseDarkColor;
  border-radius: $radiusInner;
  margin: 0 0 1rem;
  @include clearfix();
  .user-single {
    margin: 0;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $baseColor;
    }
  }
}
