@import '../../_variables.scss';

.signup-page {
  &__title {
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: 4px;
  }
  &__helper {
    font-size: 0.9em;
    font-style: italic;
    color: $textLightColor;
    margin-top: 24px;
  }
  &__selector {
    // outline: 1px solid #e4e2e2;
    border: 1px solid #e4e2e2;
    border-radius: 8px;
    padding: 12px;
    margin: 3px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;
    min-width: 150px;
    cursor: pointer;
    position: relative;
    div {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &:hover::before {
      border: 2px solid $secondaryLightColor;
      top: -1px;
      left: -1px;
      border-radius: 8px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      content: '';
      position: absolute;
    }

    .button {
      margin-top: 16px;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
  &__image {
    margin-bottom: 16px;
    width: 100%;
  }
  &__main {
    justify-content: center;
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__text-container {
    width: 100%;
    p {
      width: 100%;
      font-family: Helvetica;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
