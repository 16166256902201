.label {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1em;
  padding: 0.2em 1em;
  margin-top: 0.1em;
  background: darken($baseColor, 10%);
  color: darken($textLightColor, 10%);
  border-radius: 1em;

  &.-primary {
    background: $primaryLightColor;
  }

  &.-secondary {
    background: $secondaryLightColor;
  }

  &.-click {
    cursor: pointer;
    color: white;
    padding-bottom: 0.4em;
  }
}
