.footer-menu {
  font-size: 0.8em;
  margin: 0 5% 8em 5%;
  text-align: center;
  a {
    display: inline-block;
    padding: 0.5em 0.5em;
    color: $textLightColor;
    &:hover,
    &:focus {
      color: $textColor;
      text-decoration: underline;
    }
  }
  button {
    position: relative;
    top: 0.2em;

    @include respond-to('mobile') {
      font-size: 1rem;
      padding: 0.625em 2.5em;
      width: 80%;
    }
  }
}

.footer-action {
  width: calc(100% + 4rem);
  margin: 2rem -2rem -2rem -2rem;
  padding: 1rem 2rem;
  border-radius: 0 0 $radiusOuter $radiusOuter;
  background: $baseColor;
  overflow: auto;

  .button {
    margin-bottom: 0;

    @include respond-to('mobile') {
      margin: 0 0 20px 0;
      width: 100%;
    }
  }

  .file-upload {
    @include respond-to('mobile') {
      margin: 0;

      & + .button {
        width: calc(100% - 80px);
        margin: 0;
      }
    }
  }
}
