.file-upload {
  display: inline-block;
  .button {
    padding: 0.325em 0.75em;
    margin: 0px;
  }
  .file-remove {
    .icon-cancel {
      display: none;
    }
    &:hover,
    &:focus {
      .icon-cancel {
        display: inline-block;
      }
      .icon-camera {
        display: none;
      }
    }
  }
}

// File Upload
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  // max-width: 80%;
  // font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}
