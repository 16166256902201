@import '../../_variables.scss';
@import '../../mixins/_helpers.scss';
@import '../../mixins/_forms.scss';

$family-permissions-profile-size: 54px;
$invite-column-half: calc(50% - 12px);

.family-permissions {
  display: flex;
  flex-wrap: wrap;
  // padding: 42px 24px 24px 24px;

  &__profile {
    flex: 0 0 $family-permissions-profile-size;
    height: $family-permissions-profile-size;
    width: $family-permissions-profile-size;
    margin-right: 24px;
    margin-bottom: 24px;
    border-radius: 50%;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  &__relationship {
    font-weight: lighter;
    color: $textLightColor;
  }

  &__detail {
    flex: 1;
    margin-bottom: 24px;
  }

  &__description {
    color: $textLightColor;
    margin-bottom: 24px;
  }

  &__label {
    flex: 1 1 100%;
    font-weight: bold;
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__permissions {
    flex: 1 1 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    @include respond-to('mobile') {
      flex-basis: 100%;
    }
  }

  &__form {
    width: 100%;

    button:last-of-type {
      margin-bottom: 0;
    }
  }
}
