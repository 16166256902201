@import '../../mixins/_forms.scss';
@import '../../mixins/_helpers.scss';

.goal-edit {
  @include forms();

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__row {
    display: flex;
    flex-direction: row;
    > div {
      width: 50%;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__label {
    flex: 0 0 100%;
    margin-bottom: 2em;
  }

  &__title {
    flex: 1;
  }

  &__timeframe {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 -0.3em 2em 5em;

    @include respond-to('mobile') {
      flex: 0 0 calc(100% + 0.6em);
      margin-left: -0.3em;
    }

    label {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
      margin: auto 0.5em 0 0.5em;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      width: 100%;
    }
  }

  &__section {
    border: 1px solid $baseDarkColor;
    background: $baseColor;
    padding: 1em;
    border-radius: $radiusOuter;
  }

  &__section-action {
    text-align: center;
    button {
      margin-bottom: 0;
    }
  }

  &__step {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    position: relative;
    border-radius: 1.5em;

    label {
      flex: 1;
      margin-bottom: 0px;
    }

    input {
      margin-bottom: 0px;
      padding-right: 3em;
    }

    .draggable {
      position: absolute;
      right: 70px;
      height: 20px;
      width: 20px;
      top: 15px;
      cursor: grab;
      @include respond-to('mobile') {
        right: 50px;
      }
    }

    button {
      padding: 1em;
      margin: 0;
      line-height: 1em;
      height: 100%;
      width: auto;

      @include respond-to('mobile') {
        padding-right: 0;
      }
    }
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5em;
    margin: 0 -1em 0 -1em;
  }

  &__toggle-button {
    padding: 0.75em 2em;
    font-size: 1rem;
    border-radius: $radiusOuter;
    border: 1px solid $baseDarkColor;
    cursor: pointer;
    outline: none;
    color: $textLightColor;
    font-weight: lighter;
    margin: 0 1em 0.5em 1em;

    @include respond-to('tablet') {
      padding: 0.75em 1.5em;
    }

    @include respond-to('mobile') {
      flex: 0 0 calc(100% - 2em);
    }

    &:hover {
      text-decoration: none;
      background-color: $secondaryLighterColor;
    }
  }

  &__toggle-selected {
    background-color: $secondaryLightColor;
    border-color: $secondaryLightColor;
    color: white;

    &:hover {
      background-color: $secondaryLightColor;
    }
  }

  &__actions {
    flex: 1;
    button {
      margin-bottom: 0;
    }
  }

  .team-select {
    margin-bottom: 0;
  }
}
