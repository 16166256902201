@font-face {
  font-family: 'entypo';
  src: url('../fonts/entypo.eot?59575301');
  src: url('../fonts/entypo.eot?59575301#iefix') format('embedded-opentype'),
    url('../fonts/entypo.woff?59575301') format('woff'),
    url('../fonts/entypo.ttf?59575301') format('truetype'),
    url('../fonts/entypo.svg?59575301#entypo') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'entypo';
    src: url('../font/entypo.svg?59575301#entypo') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before,
.tweet:before,
.read-more:after,
.notification:before {
  font-family: 'entypo';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

// Spinning Icons
// --------------------------

.animate-spin:before {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

span.icon-facebook:before {
  padding-right: 0.2em;
}
span.icon-twitter:before {
  padding-right: 0.3em;
}

.icon-note:before {
  content: '\e800';
} /* '' */
.icon-cancel-circled:before {
  content: '\e801';
} /* '' */
.icon-cancel-squared:before {
  content: '\e802';
} /* '' */
.icon-note-beamed:before {
  content: '\e803';
} /* '' */
.icon-upload-cloud:before {
  content: '\e804';
} /* '' */
.icon-music:before {
  content: '\e805';
} /* '' */
.icon-plus:before {
  content: '\e806';
} /* '' */
.icon-bookmark:before {
  content: '\e807';
} /* '' */
.icon-chat:before {
  content: '\e808';
} /* '' */
.icon-bell:before {
  content: '\e809';
} /* '' */
.icon-light-down:before {
  content: '\e80a';
} /* '' */
.icon-basket:before {
  content: '\e80b';
} /* '' */
.icon-down:before {
  content: '\e80c';
} /* '' */
.icon-left-dir:before {
  content: '\e80d';
} /* '' */
.icon-left-circled:before {
  content: '\e80e';
} /* '' */
.icon-search:before {
  content: '\e80f';
} /* '' */
.icon-mail:before {
  content: '\e810';
} /* '' */
.icon-heart:before {
  content: '\e811';
} /* '' */
.icon-flashlight:before {
  content: '\e812';
} /* '' */
.icon-heart-empty:before {
  content: '\e813';
} /* '' */
.icon-star:before {
  content: '\e814';
} /* '' */
.icon-star-empty:before {
  content: '\e815';
} /* '' */
.icon-user:before {
  content: '\e816';
} /* '' */
.icon-users:before {
  content: '\e817';
} /* '' */
.icon-user-add:before {
  content: '\e818';
} /* '' */
.icon-video:before {
  content: '\e819';
} /* '' */
.icon-camera:before {
  content: '\e81a';
} /* '' */
.icon-picture:before {
  content: '\e81b';
} /* '' */
.icon-layout:before {
  content: '\e81c';
} /* '' */
.icon-check:before {
  content: '\e81d';
} /* '' */
.icon-tag:before {
  content: '\e81e';
} /* '' */
.icon-cancel:before {
  content: '\e81f';
} /* '' */
.icon-eye:before {
  content: '\e820';
} /* '' */
.icon-lock-open:before {
  content: '\e821';
} /* '' */
.icon-menu:before {
  content: '\e822';
} /* '' */
.icon-lock:before {
  content: '\e823';
} /* '' */
.icon-attach:before {
  content: '\e824';
} /* '' */
.icon-link:before {
  content: '\e825';
} /* '' */
.icon-home:before {
  content: '\e826';
} /* '' */
.icon-back:before {
  content: '\e827';
} /* '' */
.icon-info-circled:before {
  content: '\e828';
} /* '' */
.icon-info:before {
  content: '\e829';
} /* '' */
.icon-help-circled:before {
  content: '\e82a';
} /* '' */
.icon-help:before {
  content: '\e82b';
} /* '' */
.icon-minus-circled:before {
  content: '\e82c';
} /* '' */
.icon-minus:before {
  content: '\e82d';
} /* '' */
.icon-plus-squared:before {
  content: '\e82e';
} /* '' */
.icon-plus-circled:before {
  content: '\e82f';
} /* '' */
.icon-minus-squared:before {
  content: '\e830';
} /* '' */
.icon-bookmarks:before {
  content: '\e831';
} /* '' */
.icon-flag:before {
  content: '\e832';
} /* '' */
.icon-thumbs-up:before {
  content: '\e833';
} /* '' */
.icon-upload:before {
  content: '\e834';
} /* '' */
.icon-thumbs-down:before {
  content: '\e835';
} /* '' */
.icon-download:before {
  content: '\e836';
} /* '' */
.icon-reply:before {
  content: '\e837';
} /* '' */
.icon-reply-all:before {
  content: '\e838';
} /* '' */
.icon-forward:before {
  content: '\e839';
} /* '' */
.icon-quote:before {
  content: '\e83a';
} /* '' */
.icon-code:before {
  content: '\e83b';
} /* '' */
.icon-export:before {
  content: '\e83c';
} /* '' */
.icon-pencil:before {
  content: '\e83d';
} /* '' */
.icon-feather:before {
  content: '\e83e';
} /* '' */
.icon-print:before {
  content: '\e83f';
} /* '' */
.icon-retweet:before {
  content: '\e840';
} /* '' */
.icon-keyboard:before {
  content: '\e841';
} /* '' */
.icon-comment:before {
  content: '\e842';
} /* '' */
.icon-book:before {
  content: '\e843';
} /* '' */
.icon-book-open:before {
  content: '\e844';
} /* '' */
.icon-newspaper:before {
  content: '\e845';
} /* '' */
.icon-doc-text-inv:before {
  content: '\e846';
} /* '' */
.icon-doc-text:before {
  content: '\e847';
} /* '' */
.icon-doc-landscape:before {
  content: '\e848';
} /* '' */
.icon-doc:before {
  content: '\e849';
} /* '' */
.icon-trash:before {
  content: '\e84a';
} /* '' */
.icon-cup:before {
  content: '\e84b';
} /* '' */
.icon-compass:before {
  content: '\e84c';
} /* '' */
.icon-direction:before {
  content: '\e84d';
} /* '' */
.icon-map:before {
  content: '\e84e';
} /* '' */
.icon-location:before {
  content: '\e84f';
} /* '' */
.icon-address:before {
  content: '\e850';
} /* '' */
.icon-vcard:before {
  content: '\e851';
} /* '' */
.icon-alert:before {
  content: '\e852';
} /* '' */
.icon-attention:before {
  content: '\e853';
} /* '' */
.icon-folder:before {
  content: '\e854';
} /* '' */
.icon-archive:before {
  content: '\e855';
} /* '' */
.icon-box:before {
  content: '\e856';
} /* '' */
.icon-rss:before {
  content: '\e857';
} /* '' */
.icon-phone:before {
  content: '\e858';
} /* '' */
.icon-cog:before {
  content: '\e859';
} /* '' */
.icon-tools:before {
  content: '\e85a';
} /* '' */
.icon-share:before {
  content: '\e85b';
} /* '' */
.icon-shareable:before {
  content: '\e85c';
} /* '' */
.icon-bag:before {
  content: '\e85d';
} /* '' */
.icon-calendar:before {
  content: '\e85e';
} /* '' */
.icon-login:before {
  content: '\e85f';
} /* '' */
.icon-logout:before {
  content: '\e860';
} /* '' */
.icon-mic:before {
  content: '\e861';
} /* '' */
.icon-mute:before {
  content: '\e862';
} /* '' */
.icon-docs:before {
  content: '\e863';
} /* '' */
.icon-sound:before {
  content: '\e864';
} /* '' */
.icon-volume:before {
  content: '\e865';
} /* '' */
.icon-clock:before {
  content: '\e866';
} /* '' */
.icon-hourglass:before {
  content: '\e867';
} /* '' */
.icon-down-open-mini:before {
  content: '\e868';
} /* '' */
.icon-up-open:before {
  content: '\e869';
} /* '' */
.icon-right-open:before {
  content: '\e86a';
} /* '' */
.icon-left-open:before {
  content: '\e86b';
} /* '' */
.icon-down-open:before {
  content: '\e86c';
} /* '' */
.icon-right-circled:before {
  content: '\e86d';
} /* '' */
.icon-down-circled:before {
  content: '\e86e';
} /* '' */
.icon-up-circled:before {
  content: '\e86f';
} /* '' */
.icon-arrow-combo:before {
  content: '\e870';
} /* '' */
.icon-window:before {
  content: '\e871';
} /* '' */
.icon-publish:before {
  content: '\e872';
} /* '' */
.icon-popup:before {
  content: '\e873';
} /* '' */
.icon-resize-small:before {
  content: '\e874';
} /* '' */
.icon-resize-full:before {
  content: '\e875';
} /* '' */
.icon-block:before {
  content: '\e876';
} /* '' */
.icon-adjust:before {
  content: '\e877';
} /* '' */
.icon-light-up:before {
  content: '\e878';
} /* '' */
.icon-lamp:before {
  content: '\e879';
} /* '' */
.icon-left-open-mini:before {
  content: '\e87a';
} /* '' */
.icon-right-open-mini:before {
  content: '\e87b';
} /* '' */
.icon-up-open-mini:before {
  content: '\e87c';
} /* '' */
.icon-down-open-big:before {
  content: '\e87d';
} /* '' */
.icon-left-open-big:before {
  content: '\e87e';
} /* '' */
.icon-right-open-big:before {
  content: '\e87f';
} /* '' */
.icon-arrows-ccw:before {
  content: '\e880';
} /* '' */
.icon-cw:before {
  content: '\e881';
} /* '' */
.icon-ccw:before {
  content: '\e882';
} /* '' */
.icon-up-open-big:before {
  content: '\e883';
} /* '' */
.icon-level-down:before {
  content: '\e884';
} /* '' */
.icon-up-thin:before {
  content: '\e885';
} /* '' */
.icon-left-thin:before {
  content: '\e886';
} /* '' */
.icon-progress-1:before {
  content: '\e887';
} /* '' */
.icon-progress-2:before {
  content: '\e888';
} /* '' */
.icon-right-thin:before {
  content: '\e889';
} /* '' */
.icon-progress-3:before {
  content: '\e88a';
} /* '' */
.icon-target:before {
  content: '\e88b';
} /* '' */
.icon-palette:before {
  content: '\e88c';
} /* '' */
.icon-list:before {
  content: '\e88d';
} /* '' */
.icon-list-add:before {
  content: '\e88e';
} /* '' */
.icon-signal:before {
  content: '\e88f';
} /* '' */
.icon-level-up:before {
  content: '\e890';
} /* '' */
.icon-left:before {
  content: '\e891';
} /* '' */
.icon-shuffle:before {
  content: '\e892';
} /* '' */
.icon-trophy:before {
  content: '\e893';
} /* '' */
.icon-loop:before {
  content: '\e894';
} /* '' */
.icon-right:before {
  content: '\e895';
} /* '' */
.icon-battery:before {
  content: '\e896';
} /* '' */
.icon-back-in-time:before {
  content: '\e897';
} /* '' */
.icon-switch:before {
  content: '\e898';
} /* '' */
.icon-up:before {
  content: '\e899';
} /* '' */
.icon-down-dir:before {
  content: '\e89a';
} /* '' */
.icon-play:before {
  content: '\e89b';
} /* '' */
.icon-monitor:before {
  content: '\e89c';
} /* '' */
.icon-mobile:before {
  content: '\e89d';
} /* '' */
.icon-stop:before {
  content: '\e89e';
} /* '' */
.icon-right-dir:before {
  content: '\e89f';
} /* '' */
.icon-pause:before {
  content: '\e8a0';
} /* '' */
.icon-network:before {
  content: '\e8a1';
} /* '' */
.icon-cd:before {
  content: '\e8a2';
} /* '' */
.icon-record:before {
  content: '\e8a3';
} /* '' */
.icon-up-dir:before {
  content: '\e8a4';
} /* '' */
.icon-down-bold:before {
  content: '\e8a5';
} /* '' */
.icon-to-end:before {
  content: '\e8a6';
} /* '' */
.icon-inbox:before {
  content: '\e8a7';
} /* '' */
.icon-install:before {
  content: '\e8a8';
} /* '' */
.icon-to-start:before {
  content: '\e8a9';
} /* '' */
.icon-left-bold:before {
  content: '\e8aa';
} /* '' */
.icon-right-bold:before {
  content: '\e8ab';
} /* '' */
.icon-fast-forward:before {
  content: '\e8ac';
} /* '' */
.icon-globe:before {
  content: '\e8ad';
} /* '' */
.icon-cloud:before {
  content: '\e8ae';
} /* '' */
.icon-fast-backward:before {
  content: '\e8af';
} /* '' */
.icon-down-thin:before {
  content: '\e8b0';
} /* '' */
.icon-progress-0:before {
  content: '\e8b1';
} /* '' */
.icon-up-bold:before {
  content: '\e8b2';
} /* '' */
.icon-cloud-thunder:before {
  content: '\e8b3';
} /* '' */
.icon-chart-area:before {
  content: '\e8b4';
} /* '' */
.icon-chart-bar:before {
  content: '\e8b5';
} /* '' */
.icon-flow-tree:before {
  content: '\e8b6';
} /* '' */
.icon-flow-line:before {
  content: '\e8b7';
} /* '' */
.icon-vimeo:before {
  content: '\e8b8';
} /* '' */
.icon-flickr-circled:before {
  content: '\e8b9';
} /* '' */
.icon-lastfm:before {
  content: '\e8ba';
} /* '' */
.icon-lastfm-circled:before {
  content: '\e8bb';
} /* '' */
.icon-vkontakte:before {
  content: '\e8bc';
} /* '' */
.icon-behance:before {
  content: '\e8bd';
} /* '' */
.icon-mixi:before {
  content: '\e8be';
} /* '' */
.icon-stumbleupon:before {
  content: '\e8bf';
} /* '' */
.icon-stumbleupon-circled:before {
  content: '\e8c0';
} /* '' */
.icon-flickr:before {
  content: '\e8c1';
} /* '' */
.icon-github-circled:before {
  content: '\e8c2';
} /* '' */
.icon-flow-cascade:before {
  content: '\e8c3';
} /* '' */
.icon-flow-branch:before {
  content: '\e8c4';
} /* '' */
.icon-chart-line:before {
  content: '\e8c5';
} /* '' */
.icon-chart-pie:before {
  content: '\e8c6';
} /* '' */
.icon-erase:before {
  content: '\e8c7';
} /* '' */
.icon-key:before {
  content: '\e8c8';
} /* '' */
.icon-github:before {
  content: '\e8c9';
} /* '' */
.icon-dribbble-circled:before {
  content: '\e8ca';
} /* '' */
.icon-picasa:before {
  content: '\e8cb';
} /* '' */
.icon-dribbble:before {
  content: '\e8cc';
} /* '' */
.icon-infinity:before {
  content: '\e8cd';
} /* '' */
.icon-magnet:before {
  content: '\e8ce';
} /* '' */
.icon-bucket:before {
  content: '\e8cf';
} /* '' */
.icon-linkedin-circled:before {
  content: '\e8d0';
} /* '' */
.icon-paypal:before {
  content: '\e8d1';
} /* '' */
.icon-sina-weibo:before {
  content: '\e8d2';
} /* '' */
.icon-linkedin:before {
  content: '\e8d3';
} /* '' */
.icon-cc-zero:before {
  content: '\e8d4';
} /* '' */
.icon-drive:before {
  content: '\e8d5';
} /* '' */
.icon-brush:before {
  content: '\e8d6';
} /* '' */
.icon-dot-3:before {
  content: '\e8d7';
} /* '' */
.icon-database:before {
  content: '\e8d8';
} /* '' */
.icon-cc-pd:before {
  content: '\e8d9';
} /* '' */
.icon-tumblr-circled:before {
  content: '\e8da';
} /* '' */
.icon-renren:before {
  content: '\e8db';
} /* '' */
.icon-skype-circled:before {
  content: '\e8dc';
} /* '' */
.icon-tumblr:before {
  content: '\e8dd';
} /* '' */
.icon-cc-nd:before {
  content: '\e8de';
} /* '' */
.icon-megaphone:before {
  content: '\e8df';
} /* '' */
.icon-dot-2:before {
  content: '\e8e0';
} /* '' */
.icon-dot:before {
  content: '\e8e1';
} /* '' */
.icon-clipboard:before {
  content: '\e8e2';
} /* '' */
.icon-cc-sa:before {
  content: '\e8e3';
} /* '' */
.icon-pinterest-circled:before {
  content: '\e8e4';
} /* '' */
.icon-cc-remix:before {
  content: '\e8e5';
} /* '' */
.icon-thermometer:before {
  content: '\e8e6';
} /* '' */
.icon-soundcloud:before {
  content: '\e8e7';
} /* '' */
.icon-cc-share:before {
  content: '\e8e8';
} /* '' */
.icon-google-circles:before {
  content: '\e8e9';
} /* '' */
.icon-skype:before {
  content: '\e8ea';
} /* '' */
.icon-flattr:before {
  content: '\e8eb';
} /* '' */
.icon-cc-nc-jp:before {
  content: '\e8ec';
} /* '' */
.icon-cc-nc-eu:before {
  content: '\e8ed';
} /* '' */
.icon-cc-nc:before {
  content: '\e8ee';
} /* '' */
.icon-cc-by:before {
  content: '\e8ef';
} /* '' */
.icon-cc:before {
  content: '\e8f0';
} /* '' */
.icon-traffic-cone:before {
  content: '\e8f1';
} /* '' */
.icon-gauge:before {
  content: '\e8f2';
} /* '' */
.icon-rocket:before {
  content: '\e8f3';
} /* '' */
.icon-flow-parallel:before {
  content: '\e8f4';
} /* '' */
.icon-vimeo-circled:before {
  content: '\e8f5';
} /* '' */
.icon-twitter:before {
  content: '\e8f6';
} /* '' */
.icon-twitter-circled:before {
  content: '\e8f7';
} /* '' */
.icon-facebook:before {
  content: '\e8f8';
} /* '' */
.icon-facebook-circled:before {
  content: '\e8f9';
} /* '' */
.icon-facebook-squared:before {
  content: '\e8fa';
} /* '' */
.icon-gplus:before {
  content: '\e8fb';
} /* '' */
.icon-gplus-circled:before {
  content: '\e8fc';
} /* '' */
.icon-pinterest:before {
  content: '\e8fd';
} /* '' */
.icon-evernote:before {
  content: '\e8fe';
} /* '' */
.icon-dropbox:before {
  content: '\e8ff';
} /* '' */
.icon-instagram:before {
  content: '\e900';
} /* '' */
.icon-qq:before {
  content: '\e901';
} /* '' */
.icon-spotify-circled:before {
  content: '\e902';
} /* '' */
.icon-spotify:before {
  content: '\e903';
} /* '' */
.icon-rdio-circled:before {
  content: '\e904';
} /* '' */
.icon-rdio:before {
  content: '\e905';
} /* '' */
.icon-smashing:before {
  content: '\e906';
} /* '' */
.icon-sweden:before {
  content: '\e907';
} /* '' */
.icon-db-shape:before {
  content: '\e908';
} /* '' */
.icon-logo-db:before {
  content: '\e909';
} /* '' */
.icon-tape:before {
  content: '\e90a';
} /* '' */
.icon-flash:before {
  content: '\e90b';
} /* '' */
.icon-graduation-cap:before {
  content: '\e90c';
} /* '' */
.icon-language:before {
  content: '\e90d';
} /* '' */
.icon-flight:before {
  content: '\e90e';
} /* '' */
.icon-moon:before {
  content: '\e90f';
} /* '' */
.icon-paper-plane:before {
  content: '\e910';
} /* '' */
.icon-ticket:before {
  content: '\e911';
} /* '' */
.icon-leaf:before {
  content: '\e912';
} /* '' */
.icon-lifebuoy:before {
  content: '\e913';
} /* '' */
.icon-droplet:before {
  content: '\e914';
} /* '' */
.icon-water:before {
  content: '\e915';
} /* '' */
.icon-air:before {
  content: '\e916';
} /* '' */
.icon-mouse:before {
  content: '\e917';
} /* '' */
.icon-briefcase:before {
  content: '\e918';
} /* '' */
.icon-credit-card:before {
  content: '\e919';
} /* '' */
.icon-floppy:before {
  content: '\e91a';
} /* '' */
.icon-suitcase:before {
  content: '\e91b';
} /* '' */
