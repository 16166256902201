//
// Buttons
// --------------------------------------------------
button {
  background-color: transparent;
  border: none;
}

.button {
  display: inline-block;
  padding: 0.625em 2.5em;
  margin: 0 0 0.625em;
  border: none;
  border-radius: 10em;
  appearance: none;
  outline: none;
  line-height: 100%;
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
  font-size: 1rem;
  margin-right: 2rem;

  @include respond-to('mobile') {
    margin: 0 0 20px 0;
    width: 100%;
  }

  @include button-style($baseDarkColor, $textColor);

  &.-loading {
    img {
      height: 1em;
    }
  }
  &.-large {
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.625em 2em;

    @include respond-to('mobile') {
      font-size: 1rem;
      padding: 0.625em 2.5em;
      margin: 0 0 20px 0;
      width: 100%;
    }
  }
  &.-medium {
    font-size: 1rem;
    padding: 0.4rem 1rem;

    @include respond-to('mobile') {
      font-size: 1rem;
      padding: 0.625em 2.5em;
      margin: 0 0 20px 0;
      width: 100%;
    }
  }
  &.-small {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;

    @include respond-to('mobile') {
      font-size: 1rem;
      padding: 0.625em 2.5em;
      margin: 0 0 20px 0;
      width: 100%;
    }
  }
  &.-tiny {
    font-size: 0.8rem;
    padding: 0.3125rem 0.8rem;

    @include respond-to('mobile') {
      font-size: 1rem;
      padding: 0.625em 2.5em;
      margin: 0 0 20px 0;
      width: 100%;
    }
  }
  &.-center {
    margin: auto;
    display: block;
  }

  &.-primary {
    @include button-style($primaryColor, white);
  }

  &.-text {
    border: 1px solid transparent;
    background: transparent;
    color: $textLightColor;

    &:hover {
      color: $primaryColor;
      text-decoration: underline;
    }

    margin: 0;
    font-size: 14px;
    padding: 0.625em 1.5rem;
  }

  &.-right {
    float: right;
    margin-left: 1rem;
    margin-right: 0;

    @include respond-to('mobile') {
      margin: inherit;
    }
  }
  &.-primary-light {
    @include button-style(transparent, $primaryColor);
    border: 1px $primaryColor solid;
    &:active,
    &:hover {
      border-color: darken($primaryColor, 8%);
      color: darken($primaryColor, 8%);
    }
  }

  // background:$secondaryLighterColor;
  &.-secondary {
    @include button-style($secondaryColor, white);
  }

  // background:$secondaryLighterColor;
  &.-secondary-light {
    @include button-style($secondaryLightColor, white);
  }
  &.-outline {
    background-color: #f9ede0;
    color: #ff583c;
    border: 1px solid #ff583c;
  }
  &.-tertiary {
    @include button-style(white, $textLightColor);
    color: darken($textLightColor, 20%);
    border: 1px $textLightColor solid;
    &:active,
    &:hover {
      border-color: #ff583c;
      background-color: #f9ede0;
      color: #ff583c;
    }
  }
  &.-link {
    border: 1px solid transparent;
    background: transparent;
    color: $textLightColor;
    &:hover {
      color: $primaryColor;
      text-decoration: underline;
    }

    &.-mini {
      margin: 0;
      padding: 0;
      line-height: 2rem;
    }

    &[disabled] {
      cursor: default;
      text-decoration: none;
      color: $textLightColor;
    }
  }
  &.-icon {
    @include button-style(transparent, $primaryColor);
    color: darken($textLightColor, 20%);
    border: none;
    &:active,
    &:hover {
      border-color: $primaryColor;
      color: $primaryColor;
    }
  }

  $toggleSize: 2.4em;
  &.-toggle {
    width: $toggleSize;
    height: $toggleSize;
    border-radius: $toggleSize;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    font-size: $toggleSize / 2;
    line-height: $toggleSize * 0.9;
    padding: 0;
    cursor: pointer;
    @include outline-button-style(transparent, $textLightColor, $textLightColor, lighten($textLightColor, 50%), $textLightColor, $textColor);
    &.-primary {
      @include outline-button-style(transparent, $primaryColor, $primaryColor, $primaryColor, $primaryColor, $baseLightColor);
    }
    &.-inverse {
      @include outline-button-style($baseLightColor, $baseLightColor, $primaryColor, $primaryColor, $primaryColor, $baseLightColor);
    }
    &.-incomplete {
      @include outline-button-style($baseColor, $textLightColor, $textLightColor, $successColor, $successColor, $textColor);
    }
    &.-complete {
      @include outline-button-style($successColor, $successColor, $textColor, lighten($successColor, 20%), $successColor, $textLightColor);
    }
    &.-small {
      width: $toggleSize * 0.75;
      height: $toggleSize * 0.75;
      font-size: $toggleSize / 2;
      line-height: $toggleSize * 0.7;
    }
  }

  $checkboxSize: 1.6em;
  &.-checkbox {
    width: $checkboxSize;
    height: $checkboxSize;
    border-radius: $radiusInner;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    font-size: $checkboxSize / 2;
    line-height: $checkboxSize * 0.9;
    padding: 0;
    cursor: pointer;
    @include outline-button-style(transparent, $textColor, $textLightColor, lighten($successColor, 20%), $textLightColor, $textLightColor);
    &.-complete {
      @include outline-button-style($successColor, $textColor, $textColor, lighten($successColor, 20%), $textColor, $textColor);
      .icon-check {
        opacity: 1;
      }
    }
    &.-small {
      width: $checkboxSize * 0.75;
      height: $checkboxSize * 0.75;
      font-size: $checkboxSize / 2;
      line-height: $checkboxSize * 0.7;
    }
  }

  &.-facebook {
    @include button-style($socialFacebook, white);
    padding-left: 0.6em;
    &.disabled {
      background: #9099ae;
      color: white;
      cursor: default;
    }
  }

  &.-twitter {
    @include button-style($socialTwitter, white);
    &.disabled {
      background-color: #aebcc7;
      color: white;
      cursor: default;
    }
  }

  // Button Icons
  [class^='icon-']:before,
  [class*=' icon-']:before {
  }
}

.wrg-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.wrg-toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.wrg-toggle-check,
.wrg-toggle-uncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0;
  transition: opacity .25s ease;
}

.wrg-toggle-check {
  left: 8px;
}

.wrg-toggle-uncheck {
  opacity: 1;
  right: 10px;
}

.wrg-toggle-uncheck span,
.wrg-toggle-check span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  position: relative;
  width: 10px;
}

.wrg-toggle-container {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: gray;
  transition: all .2s ease;
}

.wrg-toggle-circle {
  transition: all .5s cubic-bezier(.23, 1, .32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid gray;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all .25s ease;
}

.wrg-toggle--checked .wrg-toggle-check {
  opacity: 1;
}

.wrg-toggle--checked .wrg-toggle-container {
  background-color: $primaryColor;
}

.wrg-toggle--checked .wrg-toggle-uncheck {
  opacity: 0;
}

.wrg-toggle--checked .wrg-toggle-circle {
  left: 27px;
  border-color: $primaryColor;
}
