.page {
  margin-top: 5rem;

  + .page {
    margin-top: 0;
  }

  .aside {
    @include columns(4);
    margin-bottom: 2rem;

    @include respond-to('mobile') {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .bside {
    @include columns(8);
    margin-bottom: 2rem;

    @include respond-to('mobile') {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      .card {
        width: -webkit-fill-available !important;
      }
    }

    .card {
      width: 98%;
    }
  }
}

.article-errors {
  width: calc(100% + 4rem);
  position: relative;
  left: -2rem;
  top: 2rem;
  padding: 1rem 2rem;
  border-radius: 0 0 $radiusOuter $radiusOuter;
  background: $errorColor;
  color: white;
  clear: both;
  .button {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
}
