@import '../../_variables.scss';
@import '_abstract.scss';

.invite-form {
  @extend %invite-form;

  @include forms();

  &__label {
    flex: 1 1 100%;
  }

  &__section-heading {
    flex: 1 1 100%;
  }

  &__name {
    flex: 0 0 $invite-column-half;

    @include respond-to('mobile') {
      flex-basis: 100%;
    }
  }

  &__relationship {
    flex: 0 0 $invite-column-half;

    @include respond-to('mobile') {
      flex-basis: 100%;
    }
  }

  &__permissions {
    flex: 0 0 $invite-column-half;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    @include respond-to('mobile') {
      flex-basis: 100%;
    }
  }

  &__description {
    @extend %invite-form__description;
  }

  &__radio {
    @extend %invite-form__radio;

    @include respond-to('mobile') {
      flex: 1 1 auto;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }

    input {
      align-self: center;
      margin: 0 0 4px 0;
      cursor: pointer;

      @include respond-to('mobile') {
        align-self: auto;
        margin-right: 4px;
      }
    }

    &--selected {
      opacity: 1;
    }
  }

  &__date {
    flex: 0 0 $invite-column-half;
    .react-datepicker {
      &-wrapper {
        display: block;
      }
      &__input-container {
        display: block;
      }
    }
  }

  .button.-right {
    margin-left: auto;
  }
}
