.page.-professional.-clients {
  margin-bottom: 4em;
}

.client-invite-client {
  display: inline-flex;
  text-align: center;
  margin-top: 20px;
  color: #fff;
  border-radius: 15px;
  font-size: 1rem;
  padding: 0.4rem 1rem;
  background-color: #ff583c;
}

.client-container-invite-link {
  text-align: center;
}
