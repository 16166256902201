@import '../../_variables.scss';

.documents {
  &__section {
    margin-bottom: 6em;
  }

  &__heading {
    h3 {
      margin-bottom: 0px;
    }
    p {
      font-size: 14px;
      color: $textLightColor;
      margin-top: 0px;
    }
  }

  nav {
    li {
      list-style: none;
    }
  }

  span[title]:hover:after {
    content: attr(title);
    padding: 4px 8px;
    color: #333;
    position: absolute;
    left: 5px;
    top: 0;
    white-space: nowrap;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-color: white;
    z-index: 100000;
    visibility: visible;
  }

  .chonky-file-entry-description-title-extension {
    visibility: hidden;
  }
}
