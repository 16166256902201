@import '../../_variables.scss';
@import '../../mixins/_forms.scss';
@import '../../mixins/_helpers.scss';
@import './_abstract.scss';

.profile {
  &-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;
    // height: 52px;

    &-description {
        margin-top: 3px;
        font-size: 12px;
        color: gray;
    }

    button {
      margin-bottom: 0 !important;
      width: 80px !important;
      padding: 0.4rem 0.8rem !important;
    }
  }
  &-export {
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;

    @include respond-to('mobile') {
      padding: 2em;
    }

    .profile-export__button {
      top: -35px;
      right: 40px;
      position: relative;
      text-align: center;

      @include respond-to('mobile') {
        top: -65px;
        right: 0;
      }
    }
  }

  &-form {
    @include forms();

    &__section:not(:first-of-type) {
      margin-top: 5em;
    }

    &__card {
      @include respond-to('mobile') {
        margin-top: 40px;
      }
    }

    &-container {
      display: grid;
      column-gap: 20px;
      row-gap: 10px;
      grid-template-columns: 1fr;

      @media screen and (min-width: 700px) {
        grid-template-columns: 1fr 1fr !important;
      }
    }

    &__label {

      .select,
      textarea,
      input {
        margin-bottom: 0;
      }

      >small {
        font-size: 12px;
        font-weight: 400;
        color: gray;
      }
    }
    &-full_width {
      @media screen and (min-width: 700px) {
        grid-column: 1 / 3;
      }
    }

    &-helper_text {
      font-size: 10px;
    }
    &__avatar &__image {
      background-position: center;
      border-radius: 50%;
      border: 1px $secondaryColor solid;
    }

    textarea {
      resize: none !important;
    }

    [readonly] {
      border: 0px;
      padding: 0px;
    }
  }
}
