//
// Forms
// --------------------------------------------------
// Basic Styles
// -------------------
form {
  display: block;
  margin: 0;
  position: relative;
  &.-focus {
    input,
    textarea {
      width: 100%;
    }
  }
}

fieldset {
  display: block;
  margin: 1.25em 0;
  padding: 0;
}

legend {
  display: block;
  width: 100%;
  margin: 0 0 1em 0;
  font-weight: bold;
  border-bottom: 1px solid $baseColor;
}

label {
  display: block;
  font-size: 0.85em;
  margin: 0 0 0.5em;
  font-weight: normal;

  .required {
    color: $errorColor;
  }
}

// Form Elements
// -------------------
textarea,
input {
  display: block;
  padding: 0.75em;
  margin: 0 0 1em;
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  border: 1px solid $baseDarkColor;
  border-radius: 0.5em;
  outline: none;
  font-family: inherit;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover {
    border: 1px solid darken($baseColor, 3%);
  }
  &:focus {
    border: 1px solid darken($baseColor, 5%);
  }
  &:disabled {
    background: #f5f6f7;
  }
  &.MuiInputBase-input {
    border-radius: 0;
    &:hover,
    &:focus {
      border: 0;
    }
    &:disabled {
      background: transparent;
    }
  }
}

.MuiOutlinedInput-multiline,
input.MuiInputBase-input {
  padding: 12px 14px !important;
}

.disabled {
  background: #f5f6f7;
}

// Form Buttons
input[type='submit'],
input[type='button'] {
  display: block;
  border-radius: 0;
}

input[type='file'] {
  padding: 0.125em;
  margin: 0 0 0.625em;
  font-family: inherit;
  font-size: 1rem;
  -webkit-appearance: none;
  border-radius: 0;
  line-height: 100%;
}

.checkbox {
  position: relative;
  line-height: 1.4;
  padding: 0 0 0 2em;
  input[type='checkbox'] {
    position: absolute;
    left: 0.2em;
    top: 0.2em;
  }
}

// Misc Additions
textarea {
  // padding: 0.25em; // to make the textarea more writeable
}

.select {
  position: relative;
  display: block;
  margin: 0 0 1em;
  border: 1px solid $baseDarkColor;
  border-radius: 0.5em;
}

.select select {
  width: 100%;
  margin: 0;
  outline: none;
  padding: 0.75em 1em 0.75em 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  font-family: $headingFont;
  color: $textColor;
}

.select::after {
  font-family: 'entypo';
  content: '\e86c';
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  right: 22px;
  z-index: 2;
  /* These hacks make the select behind the arrow clickable in some browsers */
  pointer-events: none;
  display: none;
}

@supports (-webkit-appearance: none) or (appearance: none) or ((-moz-appearance: none) and (mask-type: alpha)) {
  /* Show custom arrow */
  .select::after {
    display: block;
  }
  /* Remove select styling */
  .select select {
    padding-right: 2em;
    /* Match-01 */
    /* inside @supports so that iOS <= 8 display the native arrow */
    background: none;
    /* Match-04 */
    /* inside @supports so that Android <= 4.3 display the native arrow */
    border: 1px solid transparent;
    /* Match-05 */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .select select:focus {
    border-color: #aaa;
    /* Match-03 */
  }
}

// Bonus HTML5 Elements
progress,
meter {
  padding: 0.125em;
  margin: 0 0 0.625em;
  font-family: inherit;
  font-size: 1rem;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  content: '\2713';
  font-weight: 900;
  border: 2px solid $secondaryLightColor;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 0;
  text-align: center;
  margin-right: 0.5em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  line-height: 16px;
}

input[readonly] + label {
  cursor: default;
}

input[type='checkbox']:not([readonly]) + label:active:before,
input[type='radio']:not([readonly]) + label:active:before {
  transform: scale(0);
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  background-color: $secondaryLightColor;
  border-color: #000;
  color: #000;
}

input[type='checkbox']:disabled + label:before,
input[type='radio']:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

input[type='radio']:checked:disabled + label:before,
input[type='checkbox']:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.react-datepicker-popper[data-placement^='bottom-end'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom-end'] .react-datepicker__triangle::before {
  left: 200px;
}
