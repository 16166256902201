@import '../../_variables.scss';
@import '../../mixins/_helpers.scss';
@import '../../mixins/_forms.scss';

$family-permissions-profile-size: 54px;
$invite-column-half: calc(50% - 12px);

.professional-profile {
  display: flex;
  flex-wrap: wrap;

  &__profile {
    flex: 0 0 $family-permissions-profile-size;
    height: $family-permissions-profile-size;
    width: $family-permissions-profile-size;
    margin-right: 24px;
    margin-bottom: 24px;
    border-radius: 50%;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
      padding: 0;
    }
  }

  &__relationship {
    font-weight: lighter;
    color: $textLightColor;
  }

  &__detail {
    flex: 1;
    margin-bottom: 24px;
  }

  &__description {
    color: $textLightColor;
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
    flex: 0 0 100%;
    font-size: 1em;
    margin-bottom: 1em;
    align-items: center;

    i {
      font-size: 1.5em;
      color: $secondaryLightColor;
      align-self: flex-start;
    }

    > a,
    > p,
    > div {
      display: inline-block;
      color: $textLightColor;
      padding-left: 12px;
      margin: 0;
      white-space: pre-wrap;
    }
  }

  .button {
    flex: 1;
    margin: 12px 0 0 0;
  }
}
