//
// Variables
// --------------------------------------------------
// Fonts
// -------------------
$headingFont: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
$paragraphFont: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
// Colours
// -------------------
$primaryColor: #ff583c;
$primaryLightColor: #ffb3a6;
$primaryLighterColor: #f9ede0;
$secondaryColor: #00a694;
$secondaryLightColor: #6bd4c9;
$secondaryLighterColor: lighten($secondaryLightColor, 30%);
$tertiaryColor: #1383a4;
$textColor: #0c1737;
$textLightColor: #766b69;
$textLighterColor: #ffffffcc;
$borderLightColor: rgba(159, 159, 159, 0.3);
$baseColor: #f5f6f7;
$baseLightColor: #ffffff;
$baseDarkColor: #e4e2e2;
$radiusOuter: 0.5rem;
$radiusInner: 0.25rem;
$shadowShallow: 0 0.25em 0.5em rgba(0, 0, 0, 0.05);
$shadowDeep: 0 0.5em 1em rgba(0, 0, 0, 0.1);
$socialFacebook: #3b5998;
$socialTwitter: #00aced;
$socialInstagram: #3f729b;
$socialYoutube: #cd201f;
$errorColor: #aa0000;
$successColor: #6bd4c9;
$baseFontSize: 16;

// Breakpoints
// -------------------
$breakpoints: (
  'mobile-p': (
    max-width: 360px,
  ),
  'mobile-l': (
    max-width: 480px,
  ),
  'mobile': (
    max-width: 736px,
  ),
  'tablet': (
    max-width: 1199px,
  ),
  'desktop': (
    min-width: 1200px,
  ),
);

// Include gutter on outside
$gutterOnOutside: true;

// Breakpoints Grid Settings
// -------------------
$grid-settings: (
  base: (
    container-columns: 12,
    gutter: 1%,
    max-width: 1100px,
  ),
  desktop: (
    container-columns: 12,
    gutter: 1%,
    max-width: 1200px,
  ),
  tablet: (
    container-columns: 12,
    gutter: 1%,
    max-width: 1200px,
  ),
  mobile: (
    container-columns: 1,
    gutter: 5%,
  ),
);

// Easing functions
// -------------------
$naturalEase: cubic-bezier(0.64, 0.57, 0.67, 1.53);
