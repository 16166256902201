@import '../../_variables.scss';
@import '../../mixins/_helpers.scss';

.user-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  background: $baseLightColor;
  box-shadow: $shadowShallow;
  border-radius: $radiusOuter;
  margin-bottom: 2rem;

  &--professional {
    cursor: pointer;
  }

  &__avatar {
    flex: 0 0 2.4em;
    margin: 0.75em 0.5em 0.75em 1em;

    .image {
      width: 2.4em;
      height: 2.4em;
    }
  }

  &__details {
    flex: 1;
    // margin: 0 1em;
    margin: 0.75em 0.5em;
  }

  &__name {
    margin: 0 0 0.25em 0;
  }

  &__relationship {
    margin: 0;
    color: $textLightColor;
  }

  &__permission {
    margin-top: 5px;
  }

  &__invite_sent {
    margin-top: 5px;
    background: $primaryLightColor;
  }

  &__invite_accepted {
    margin-top: 5px;
    background: $secondaryLighterColor;
  }

  &__appointment {
    flex: 1;

    &-title {
      font-size: 0.7em;
      margin: 0 0 0.2em 0;
      color: $textLightColor;

      @include respond-to('mobile') {
        display: none;
      }
    }

    .label {
      white-space: pre;

      @include respond-to('mobile') {
        position: relative;
        display: block;
        width: 100%;
        text-align: center;
        padding: 1em;
        border-radius: $radiusOuter;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: 0.9em;

        &::before {
          position: absolute;
          // top: 0px;
          left: 24px;
          content: '\e866';
          font-family: 'entypo';
          font-weight: lighter;
          font-size: 1.75em;
          color: $textColor;
        }
      }
    }

    @include respond-to('mobile') {
      order: 4;
      flex: 0 0 100%;
    }
  }

  &__action {
    flex: 0 0 2.2em;

    .button.-toggle {
      margin: 0;
      border: 0;
      background: transparent;
      width: 2.2em;
      height: 2.2em;
      font-size: 1.4em;
      &:hover {
        color: $primaryLightColor;
      }
    }
  }
}
