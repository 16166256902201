// --------------------------------------------------
// Lists
// --------------------------------------------------

.list {
  padding: 0;

  &__item {
    display: block;
    padding: 0.75em;
    margin: 0 0 1em;
    width: 100%;
    max-width: 100%;
    border: 1px solid #e4e2e2;
    border-radius: 0.5em;
    outline: none;
    font-family: inherit;
    font-size: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    overflow: auto;

    &-image {
      background-image: url('/assets/img/blank-creditcard.png');
      width: 36px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: 24px 16px;
      display: inline-block;
      padding-right: 10px;
    }
    &-avatar {
      height: 2rem;
      width: 2rem;
      border-radius: 5rem;
      border: 1px $secondaryColor solid;
      background-color: darken($baseColor, 20%);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      display: inline-block;
      margin-right: 10px;
    }
    &-name {
      width: 25%;
      display: inline-block;
      line-height: 32px;

      @include respond-to('mobile') {
        width: 75%;
      }

      small {
        font-size: 0.8rem;
      }
    }
    &-paid {
      width: 25%;
      display: inline-block;
      line-height: 32px;
    }
    &-action {
      float: right;
      width: 40%;
      display: inline-block;
      line-height: 32px;

      .description,
      .buttons {
        width: 100%;
        text-align: right;
      }

      @include respond-to('mobile') {
        width: 100%;
        margin-top: 10px;
      }
    }
    &-description {
      width: 80%;
      display: inline-block;
      line-height: 32px;
    }
    &-total {
      width: 20%;
      text-align: right;
      display: inline-block;
      line-height: 32px;
    }
    &-number {
      padding-left: 1rem;
    }
    &-exp {
      float: right;
      padding-right: 1rem;
    }
    &-cvc {
      float: right;
      padding-right: 1rem;
    }
    .button {
      margin: 0;

      &.-icon {
        padding: 0.2em 0 0.2em 0.8em;
      }
    }
    &.active {
      border: 1px solid #ff583c;
    }
  }
}
