@import '../variables.scss';

.modal {
  position: fixed;
  top: -1000px;
  left: -1000px;
  bottom: -1000px;
  right: -1000px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2001;
  transform-origin: 50% 50% 0px !important;
}

.modal-inner {
  position: relative;
  color: darken($textColor, 5%);
  background: white;
  padding: 2rem;
  max-height: 100vh;
  overflow: hidden !important;
  border-radius: $radiusOuter;
  box-shadow: 0 2em 4em rgba(0, 0, 0, 0.5);
  width: auto;
  max-width: 45em;

  .dismiss {
    position: absolute;
    top: 1em;
    right: 1em;
    background: none;
    border: none;
    z-index: 1;
    cursor: pointer;
  }
  .heading {
    width: calc(100% + 4rem);
    position: relative;
    left: -2rem;
    top: -2rem;
    padding: 1.5rem 2rem;
    text-align: center;
    border-radius: $radiusOuter $radiusOuter 0 0;
    background-color: $baseColor;
    background-repeat: repeat;

    &.-primary {
      background-color: $primaryLightColor;
      background-image: url('/assets/img/pattern.primary.png');
      color: $textColor;
    }
    &.-secondary {
      background-color: $secondaryLightColor;
      background-image: url('/assets/img/pattern.secondary.png');
      color: $textColor;
    }
  }
  .image {
    flex: 0 0 50%;
    width: 50%;
    padding: 1.5rem 2rem;

    @include respond-to('mobile') {
      display: none;
    }
  }
  .action {
    width: calc(100% + 4rem);
    position: relative;
    left: -2rem;
    top: 2rem;
    padding: 1rem 2rem;
    border-radius: 0 0 $radiusOuter $radiusOuter;
    background: $baseColor;
    text-align: center;
    display: flex;
    justify-content: space-between;
    @include respond-to('mobile') {
      padding-left: 1em;
      padding-right: 1em;
    }
    .button {
      margin: 0 0.5em;
      @include respond-to('mobile') {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
  .textarea {
    max-height: 60vh;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.modal {
  .modal-inner {
    max-width: 72em;
    overflow: initial;
    height: auto;
    overflow-y: auto;

    @include respond-to('tablet') {
      max-width: 90vw;
    }

    @include respond-to('mobile') {
      max-width: 100vw;
    }
  }

  &.-focus {
    .modal-inner {
      max-width: 24em;
      overflow: initial;
    }
  }

  &.-medium {
    .modal-inner {
      max-width: 100vw;
      @media screen and (min-width: 600px) {
        max-width: 37em !important;
        overflow: initial;
      }
    }
  }

  .form-password {
    width: 32em;
  }

  &.-mini {
    .modal-inner {
      max-width: 22em;
      overflow: initial;
    }
  }

  // dual panel
  &.-dual,
  &.-dual-img,
  &.-flex {
    .modal-inner {
      display: flex;
    }
  }

  &.-dual,
  &.-dual-img {
    .modal-inner {
      padding: 0px;
    }
  }

  &.-dual {
    .modal-inner {
      @include respond-to('mobile') {
        flex-direction: column;
      }
    }
  }

  // Posts inside Modals
  &.-post {
    .modal-inner {
      padding: 0;
    }
    .post-single .post {
      margin: 0;
      width: 100%;
      padding: 1em;
    }
    .action,
    .step-add {
      display: none;
    }

    // Goals inside posts
    .goal-single {
      padding: 0 0 0 1em;
      .section > p {
        // padding:0 1em;
      }
      .goal-single {
        padding: 0;
      }
      .goal-header {
        display: none;
      }
      .step {
        background: $baseColor;
        padding: 0;
        .content {
          padding: 1em;
        }
        .title {
          display: inline-block;
          margin: 0 0.5em 0 0;
        }
        .meta {
          display: inline-block;
          padding: 0;
        }
      }
    }
    // .goal-single .goal-header {
    // 	padding:0;
    // 	box-shadow:none;
    // 	.title {
    // 		h3 {
    // 			margin:0;
    // 		}
    // 	}
    // }
    // .goal-steps {
    // 	.step {
    // 		padding:.5em 0;
    // 	}
    // 	.content {
    // 		padding-left:0;
    // 	}
    // }
  }
}

.modal__billing {
  .MuiDialog-container {
    .MuiDialog-paper {
      overflow: hidden;
    }
  }

  .account-form {
    margin: 20px;
  }

  .footer-action {
    padding: 1rem 2rem 1.5rem 2rem;
  }
}
