@import '../../variables';
@import '../../mixins/grid';
@import '../../mixins/helpers';

.profile-aside-text {
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

.profile-aside-text-green {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #40bdaf;
  cursor: pointer;
}

.profile-aside-container {
  align-items: baseline;

  @include columns(12);
  display: flex !important;
  float: none !important;

  div {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #ff583c;
    background-color: rgba(255, 88, 60, 0.15);
    padding-left: 8px;
    padding-top: 8px;
    align-items: center;
    margin-right: 12px;
    line-height: 12px;
  }
  p {
    flex: 1 1 auto;
  }
}
