@import '../../mixins/_helpers.scss';
@import '../../variables.scss';

.billing-details {
  padding-top: 1em;

  &__section {
    border: 1px solid $baseDarkColor;
    border-radius: $radiusOuter;
    padding: 1em 2em;
    color: $textLightColor;
    margin-bottom: 2em;
  }

  &__card {
    margin-top: 1em;
    overflow: auto;

    span {
      margin-right: 2em;

      @include respond-to('mobile') {
        margin: 0 0 20px 0;
        display: block;
      }
    }
  }
}
